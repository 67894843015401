import React from 'react';
import PropTypes from 'prop-types';

const PageHeader = ({ title, children }) => {
  return (
    <div className="flex justify-between items-center mb-4 px-4">
      <h1 className="text-2xl font-bold text-gray-900 dark:text-white">{title}</h1>
      {children}
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default PageHeader;

