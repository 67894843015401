import { apiClient } from '../AxiosConfig';
import {
  Companies,
  MyCompanies,
  MyWorkspace,
  CompaniesSelectList,
  BranchesGetForMyCompany
} from '../../../constants/paths';

class CompanyService {
  async getCompanies(pageSize = 1000) {
    const response = await apiClient.get(`${Companies}/filter`, {
      params: { pageSize }
    });
    return response.data.result;
  }

  async getCompanyById(companyId) {
    const response = await apiClient.get(`${Companies}/${companyId}`);
    return response.data.result;
  }

  async getMyCompanies() {
    const response = await apiClient.get(MyCompanies);
    return response.data.result;
  }

  async getWorkspace() {
    const response = await apiClient.get(MyWorkspace);
    return response.data.result;
  }

  async getCompanySelectList() {
    const response = await apiClient.get(CompaniesSelectList);
    return response.data.result;
  }

  async getBranchesForMyCompany() {
    const response = await apiClient.get(BranchesGetForMyCompany);
    return response.data.result;
  }

  /**
   * Get full company details
   * @param {string} companyId - Company identifier
   * @returns {Promise<Object>} Company details
   */
  async getCompanyDetails(companyId) {
    const response = await apiClient.get(`${Companies}/${companyId}`);
    return response.data.result;
  }

  /**
   * Get only company name
   * @param {string} companyId - Company identifier
   * @returns {Promise<string>} Company name
   */
  async getCompanyName(companyId) {
    try {
      const response = await apiClient.get(`${Companies}/${companyId}`);
      return response.data.result.name;
    } catch (error) {
      console.error('Error fetching company name:', error);
      return 'Unable to fetch company name';
    }
  }

  /**
   * Update company details
   * @param {Object} companyData - Company update data
   * @returns {Promise<Object>} Updated company data
   */
  async updateCompany(companyData) {
    const response = await apiClient.put(Companies, companyData);
    return response.data;
  }
}

const companyService = new CompanyService();
export default companyService;
