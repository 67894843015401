import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '../../../contexts/ThemeContext';
import BranchService from '../../../services/api/calls/BranchService.jsx';
import LoadingSpinner from '../../../components/misc/LoadingSpinner';
import ErrorPage from '../../error/ErrorPage';
import BranchDetailsForm from './components/branchDetailForm.jsx';
import {
  TabNavigation,
  NavigationHeader,
  useFormHandlers,
  handleBackNavigation,
  getCommonStyles,
  handleApiError
} from '../../../components/helpers/CommonHelper';
import {
  FormOutlined,
  FileTextOutlined
} from '@ant-design/icons';

const INITIAL_BRANCH_STATE = {
  id: '',
  name: '',
  reference: '',
  address1: '',
  address2: '',
  town: '',
  county: '',
  country: '',
  zipPostCode: '',
  accountcode: '',
  tel: '',
  codes: [],
  state: 0,
  distributionEmails: [],
  consentAgreementId: null,
  consentAgreementName: null,
  company: { id: '', name: '' }
};

const BranchDetailPage = () => {
  const [branch, setBranch] = useState(INITIAL_BRANCH_STATE);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('details');
  const [newCode, setNewCode] = useState('');

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode } = useTheme();
  const { containerClass } = getCommonStyles(isDarkMode);

  const fetchBranchDetails = async (branchId) => {
    try {
      const branchData = await BranchService.getBranchDetails(branchId);
      setBranch(branchData);
      return branchData;
    } catch (error) {
      throw new Error(handleApiError(error));
    }
  };

  const {
    isEditing,
    handleEditClick,
    handleCancelEdit,
    handleFieldChange
  } = useFormHandlers({
    fetchDetails: () => fetchBranchDetails(id),
    onSave: (name, value) => {
      setBranch(prev => ({
        ...prev,
        [name]: value
      }));
    },
    initialData: branch
  });

  const tabs = [
    { id: 'details', label: 'Details', icon: <FormOutlined /> },
    { id: 'logs', label: 'Logs', icon: <FileTextOutlined /> }
  ];

  useEffect(() => {
    const loadBranchDetails = async () => {
      if (!id) return;

      setLoading(true);
      setError(null);
      try {
        await fetchBranchDetails(id);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadBranchDetails();
  }, [id]);

  const handleDeleteCode = (indexToDelete) => {
    setBranch(prevBranch => ({
      ...prevBranch,
      codes: prevBranch.codes.filter((_, index) => index !== indexToDelete)
    }));
  };

  const handleAddCode = (e) => {
    e.preventDefault();
    if (newCode.trim() !== '') {
      setBranch(prevBranch => ({
        ...prevBranch,
        codes: [...prevBranch.codes, newCode.trim()]
      }));
      setNewCode('');
    }
  };

  const handleSubmit = async (values) => {
    try {
      const branchData = {
        id: branch.id,
        companyId: branch.company.id,
        consentAgreementId: branch.consentAgreementId,
        ...values,
        codes: branch.codes.filter(code => code.trim() !== ""),
      };

      await BranchService.updateBranch(branch.id, branchData);
      handleCancelEdit();
      await fetchBranchDetails(branch.id);
    } catch (error) {
      setError(handleApiError(error));
    }
  };

  const handleBack = () => {
    handleBackNavigation(navigate, location, '/client-admin/branches');
  };

  const renderDetailsTab = () => (
    <BranchDetailsForm
      branch={branch}
      isEditing={isEditing}
      onSubmit={handleSubmit}
      onCancel={handleCancelEdit}
      onEdit={handleEditClick}
      isDarkMode={isDarkMode}
      onAddCode={handleAddCode}
      onDeleteCode={handleDeleteCode}
      newCode={newCode}
      setNewCode={setNewCode}
      onFieldChange={(name, value) => handleFieldChange('main', name, value)}
    />
  );

  const renderLogsTab = () => (
    <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
          Branch Logs
        </h3>
        <p className="text-sm text-gray-600 dark:text-gray-300">
        </p>
      </div>
    </div>
  );

  if (error) return <ErrorPage isDarkMode={isDarkMode} errorMessage={error} />;
  if (loading) return <LoadingSpinner />;

  return (
    <div className={containerClass}>
      <div className="container mx-auto p-6">
        <NavigationHeader
          title={`Branch Details | ${branch.name}`}
          onBackClick={handleBack}
          isDarkMode={isDarkMode}
        />

        <TabNavigation
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={setActiveTab}
          isDarkMode={isDarkMode}
        />

        {activeTab === 'details' ? renderDetailsTab() :
         activeTab === 'logs' ? renderLogsTab() :
         <div>No content available</div>}
      </div>
    </div>
  );
};

export default BranchDetailPage;
