import React from 'react';
import PropTypes from 'prop-types';
import CodeEditor from '@uiw/react-textarea-code-editor';

const ValidateButton = ({ onClick, isDarkMode }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
      isDarkMode
        ? 'bg-green-600 hover:bg-green-700 focus:ring-green-500 text-white'
        : 'bg-green-500 hover:bg-green-600 focus:ring-green-400 text-white'
    }`}
  >
    Validate JSON
  </button>
);

ValidateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired
};

export const JsonEditor = ({
  editedJsonString,
  handleJsonChange,
  isEditing,
  isDarkMode,
  jsonError,
  isJsonValid,
  handleValidateClick,
  editorHeight,
}) => (
  <div
    className={`shadow-lg rounded-lg overflow-hidden flex flex-col ${
      isDarkMode ? 'bg-gray-700 text-gray-100' : 'bg-white text-gray-900'
    }`}
    style={{ height: editorHeight }}
  >
    <CodeEditor
      value={editedJsonString}
      language="json"
      onChange={(ev) => handleJsonChange(ev.target.value)}
      readOnly={!isEditing}
      padding={20}
      style={{
        fontSize: 14,
        height: '100%',
        width: '100%',
        backgroundColor: isDarkMode ? 'rgb(55, 65, 81)' : '#fff',
      }}
    />
    {jsonError && (
      <div className="p-2">
        <span className="text-red-500 text-sm">{jsonError}</span>
      </div>
    )}
    {isJsonValid && (
      <div className="p-2">
        <span className="text-green-500 text-sm">Please Validate Json Before Saving</span>
      </div>
    )}
    <div className={`p-2 flex justify-between items-center ${
      isDarkMode ? 'bg-gray-600' : 'bg-gray-50'
    }`}>
      <ValidateButton onClick={handleValidateClick} isDarkMode={isDarkMode} />
    </div>
  </div>
);

JsonEditor.propTypes = {
  editedJsonString: PropTypes.string.isRequired,
  handleJsonChange: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  jsonError: PropTypes.string,
  isJsonValid: PropTypes.bool.isRequired,
  handleValidateClick: PropTypes.func.isRequired,
  editorHeight: PropTypes.string.isRequired,
};
