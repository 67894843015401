import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from "../helpers/CommonHelper.jsx";

const UserInfo = ({ label, user, date }) => (
  <div className="bg-gray-100 dark:bg-gray-800 rounded-lg p-4">
    <div className="component-form-item mb-0">
      <label className="component-label text-sm text-gray-600 dark:text-gray-400">{label}</label>
      <div className="flex items-baseline space-x-1">
        <span className="text-gray-900 dark:text-gray-100 font-medium">
          {user.name}
        </span>
        <span className="text-sm text-gray-500 dark:text-gray-400">
          on {formatDate(date)}
        </span>
      </div>
    </div>
  </div>
);

UserInfo.propTypes = {
  label: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  date: PropTypes.string.isRequired,
};

export default UserInfo;
