export const handleApiError = (error) => {
  if (error.response) {
    const status = error.response.status;
    const data = error.response.data;

    switch (status) {
      case 400:
        return `Bad Request: ${data.message || 'Invalid input'}`;
      case 401:
        return 'Unauthorized: Please login again';
      case 403:
        return 'Forbidden: You do not have permission to access this resource';
      case 404:
        return 'Not Found: The requested resource does not exist';
      case 500:
        return 'Internal Server Error: Please try again later';
      default:
        return `Error ${status}: ${data.message || 'Something went wrong'}`;
    }
  } else if (error.request) {
    return 'Network Error: Please check your internet connection';
  } else {
    return error.message || 'An unexpected error occurred';
  }
};
