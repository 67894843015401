import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { grabToken } from '../../../utils/api';
import { useTheme } from '../../../contexts/ThemeContext';
import { notification } from 'antd';
import ErrorPage from '../../error/ErrorPage';
import LoadingSpinner from '../../../components/misc/LoadingSpinner';
import usePrompt from '../../../hooks/usePrompt';
import AIModelDetailsForm from './components/AIModelDetailsForm';
import AIPromptForm from './components/AIPromptForm';
import {
  TabNavigation,
  NavigationHeader,
  useFormHandlers,
  handleBackNavigation,
  getCommonStyles,
  confirmNavigation,
  handleApiError
} from '../../../components/helpers/CommonHelper';
import {
  FormOutlined,
  CodeOutlined
} from '@ant-design/icons';

const API_CONFIG = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  endpoints: {
    aiModels: '/predictionModels',
  },
};

const AIModelDetailPage = () => {
  const [model, setModel] = useState({
    id: '',
    aiServiceId: 0,
    type: 0,
    countryCode: '',
    note: '',
    targetName: '',
    narrative: '',
    outputFormat: '',
    createdDate: '',
    updatedDate: '',
    createdByUser: { id: '', name: '' },
    updatedByUser: { id: '', name: '' },
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('details');
  const [editedJsonString, setEditedJsonString] = useState('');
  const [isJsonValidated, setIsJsonValidated] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const originalValues = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const location = useLocation();

  const { containerClass } = getCommonStyles(isDarkMode);

  const tabs = [
    {
      id: 'details',
      label: 'Details',
      icon: <FormOutlined />
    },
    {
      id: 'aiPrompt',
      label: 'AI Prompt',
      icon: <CodeOutlined />
    }
  ];

  const {
    isEditing,
    setIsEditing,
    handleEditClick,
    handleCancelEdit,
  } = useFormHandlers({
    initialData: model,
    fetchDetails: () => fetchModelDetails(id),
    onSave: (name, value) => {
      setModel(prev => ({ ...prev, [name]: value }));
      setHasUnsavedChanges(true);
    },
    hasUnsavedChanges
  });

  useEffect(() => {
    if (id) {
      fetchModelDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (model.narrative) {
      try {
        const initialJsonString = JSON.stringify(JSON.parse(model.narrative), null, 2);
        setEditedJsonString(initialJsonString);
      } catch (error) {
        console.error('Error parsing narrative JSON:', error);
        setEditedJsonString(model.narrative);
      }
    }
  }, [model.narrative]);

  useEffect(() => {
    if (model && !originalValues.current) {
      originalValues.current = { ...model };
    }
  }, [model]);

  usePrompt('You have unsaved changes. Are you sure you want to leave?', hasUnsavedChanges && isEditing);

  const handleBack = () => {
    confirmNavigation(hasUnsavedChanges, (shouldNavigate) => {
      if (shouldNavigate) {
        handleBackNavigation(navigate, location, '/internal-admin/aiml');
      }
    });
  };

  const fetchModelDetails = async (modelId) => {
    setLoading(true);
    setError(null);
    try {
      const token = await grabToken();
      const response = await axios.get(`${API_CONFIG.baseURL}${API_CONFIG.endpoints.aiModels}/${modelId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
      setModel(response.data.result);
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleJsonChange = (value) => {
    setEditedJsonString(value);
    setIsJsonValidated(false);
    setHasUnsavedChanges(true);
  };

  const handleValidateClick = () => {
    try {
      JSON.parse(editedJsonString);
      setIsJsonValidated(true);
      notification.success({
        message: 'JSON is valid',
      });
    } catch (error) {
      setIsJsonValidated(false);
      notification.error({
        message: 'Invalid JSON',
        description: error.message,
      });
    }
  };

  const handleSubmit = async (formValues) => {
    if (activeTab === 'aiPrompt' && !isJsonValidated) {
      notification.warning({
        message: 'Please validate the JSON before saving',
      });
      return;
    }

    setLoading(true);
    try {
      const token = await grabToken();
      const updatedModel = {
        ...model,
        ...(activeTab === 'details' ? formValues : {}),
        ...(activeTab === 'aiPrompt' ? { narrative: editedJsonString } : {})
      };

      await axios.put(
        `${API_CONFIG.baseURL}${API_CONFIG.endpoints.aiModels}/${model.id}`,
        updatedModel,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setModel(updatedModel);
      setLoading(false);
      setHasUnsavedChanges(false);
      setIsEditing(false);
      notification.success({
        message: 'AI Model was updated',
      });

      await fetchModelDetails(updatedModel.id);
    } catch (error) {
      setLoading(false);
      const errorMessage = handleApiError(error);
      setError(errorMessage);
      notification.error({
        message: 'AI Model update failed',
        description: errorMessage,
      });
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorPage isDarkMode={isDarkMode} errorMessage={error} />;

  return (
    <div className={containerClass}>
      <div className="container mx-auto p-6">
        <NavigationHeader
          title={`AI Model Details | ${model.note}`}
          onBackClick={handleBack}
          isDarkMode={isDarkMode}
        />

        <TabNavigation
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={setActiveTab}
          isDarkMode={isDarkMode}
        />

        {activeTab === 'details' ? (
          <AIModelDetailsForm
            model={model}
            isEditing={isEditing}
            onSubmit={handleSubmit}
            onCancel={handleCancelEdit}
            onEdit={handleEditClick}
            isDarkMode={isDarkMode}
          />
        ) : (
          <AIPromptForm
            model={model}
            editedJsonString={editedJsonString}
            onJsonChange={handleJsonChange}
            isEditing={isEditing}
            onValidateClick={handleValidateClick}
            isDarkMode={isDarkMode}
          />
        )}
      </div>
    </div>
  );
};

export default AIModelDetailPage;
