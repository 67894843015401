import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { grabToken } from '../../../utils/api';
import LoadingSpinner from '../../../components/misc/LoadingSpinner';
import ErrorPage from '../../error/ErrorPage';
import FilterableTable from '../../../components/basic/FilterableTable';
import { useTheme } from '../../../contexts/ThemeContext';
import { useTableFilters } from '../../../hooks/useTableFilters';


const ProviderDataPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const {
    filters,
    currentPage,
    itemsPerPage,
    handleFilterChange,
    handlePageChange,
    handleItemsPerPageChange
  } = useTableFilters({
    name: '',
    senderServer: '',
    recipientServer: '',
  });

  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = await grabToken();
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/EmailProviders/filter`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        params: {
          pageSize: 100
        }
      });
      if (response.data && response.data.result && Array.isArray(response.data.result.list)) {
        setData(response.data.result.list);
      } else {
        setError('Received data is not in the expected format');
      }
    } catch (error) {
      setError(error.response ? `Error: ${error.response.status} - ${error.response.data}` : error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (item) => {
    navigate(`/internal-admin/providers/${item.id}`);
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorPage message={error} />;

  const filteredData = data.filter(item =>
    Object.keys(filters).every(key => {
      const filterValue = filters[key].toLowerCase();
      if (key === 'senderServer') {
        return item.sender?.server?.toLowerCase().includes(filterValue) ?? false;
      }
      if (key === 'recipientServer') {
        return item.recipient?.server?.toLowerCase().includes(filterValue) ?? false;
      }
      return item[key]?.toString().toLowerCase().includes(filterValue) ?? false;
    })
  );

  const columns = [
    {
      header: 'Name',
      accessor: 'name'
    },
    {
      header: 'Sender',
      accessor: 'senderServer',
      render: (item) => item.sender?.server
    },
    {
      header: 'Recipient',
      accessor: 'recipientServer',
      render: (item) => item.recipient?.server
    }
  ];

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="w-full h-full overflow-hidden">
      <div className="flex justify-between items-center mb-4 px-4">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Internal Admin | Providers
        </h1>
      </div>
      <FilterableTable
        columns={columns}
        data={paginatedData}
        filters={filters}
        onFilterChange={handleFilterChange}
        onRowClick={handleRowClick}
        isDarkMode={isDarkMode}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalItems={filteredData.length}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </div>
  );
};

export default ProviderDataPage;
