import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { grabToken } from '../../../utils/api';
import { format, parseISO } from 'date-fns';
import LoadingSpinner from '../../../components/misc/LoadingSpinner';
import ErrorPage from '../../error/ErrorPage';
import FilterableTable from '../../../components/basic/FilterableTable';
import { useTheme } from '../../../contexts/ThemeContext';

const API_CONFIG = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  endpoints: {
    models: '/predictionModels',
  },
  pageSize: 100,
};

const AIMLDataPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    note: '',
    type: '',
    updatedByUser: '',
    updatedDate: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = await grabToken();
      const response = await axios.get(`${API_CONFIG.baseURL}${API_CONFIG.endpoints.models}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        params: {
          pageSize: API_CONFIG.pageSize
        }
      });

      if (response.data && response.data.result && Array.isArray(response.data.result.list)) {
        setData(response.data.result.list);
      } else {
        setError('Received data is not in the expected format');
      }
    } catch (error) {
      setError(error.response ? `Error: ${error.response.status} - ${error.response.data}` : error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (property, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [property]: value
    }));
    setCurrentPage(1);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    try {
      return format(parseISO(dateString), 'dd MMM yyyy HH:mm');
    } catch (error) {
      console.error('Error formatting date:', error);
      return dateString;
    }
  };

  const getTypeDisplay = (type) => {
    switch (type) {
      case 15:
        return "AI Reply Prediction";
      case 16:
        return "AI Labels Prediction";
      default:
        return type;
    }
  };

  const handleRowClick = (item) => {
    navigate(`/internal-admin/aiml-models/${item.id}`);
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorPage errorCode={error.code} errorMessage={error.message} />;

  const filteredData = data.filter(item =>
    Object.keys(filters).every(key => {
      const filterValue = filters[key].toLowerCase();
      if (key === 'updatedByUser') {
        return item.updatedByUser?.name?.toLowerCase().includes(filterValue) ?? false;
      }
      if (key === 'updatedDate') {
        return formatDate(item[key]).toLowerCase().includes(filterValue);
      }
      if (key === 'type') {
        return getTypeDisplay(item[key]).toLowerCase().includes(filterValue);
      }
      return item[key]?.toString().toLowerCase().includes(filterValue) ?? false;
    })
  );

  const columns = [
    {
      header: 'Name',
      accessor: 'note'
    },
    {
      header: 'Type',
      accessor: 'type',
      render: (item) => getTypeDisplay(item.type)
    },
    {
      header: 'Updated By',
      accessor: 'updatedByUser',
      render: (item) => item.updatedByUser?.name
    },
    {
      header: 'Updated Date',
      accessor: 'updatedDate',
      render: (item) => formatDate(item.updatedDate)
    }
  ];

  return (
    <div className="w-full h-full overflow-hidden">
      <div className="flex justify-between items-center mb-4 px-4">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Internal Admin | AI/ML Models
        </h1>
      </div>

      <FilterableTable
        columns={columns}
        data={filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
        filters={filters}
        onFilterChange={handleFilterChange}
        onRowClick={handleRowClick}
        isDarkMode={isDarkMode}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalItems={filteredData.length}
        onPageChange={setCurrentPage}
        onItemsPerPageChange={(value) => {
          setItemsPerPage(value);
          setCurrentPage(1);
        }}
      />
    </div>


  );
};

export default AIMLDataPage;
