class JsonService {
  validateJson(jsonString) {
    try {
      const parsed = typeof jsonString === 'string' ? JSON.parse(jsonString) : jsonString;
      return {
        isValid: true,
        formatted: JSON.stringify(parsed, null, 2),
        error: null
      };
    } catch (error) {
      return {
        isValid: false,
        formatted: jsonString,
        error: `Invalid JSON: ${error.message}`
      };
    }
  }

  parseAndFormat(jsonString) {
    try {
      const parsed = typeof jsonString === 'string' ? JSON.parse(jsonString) : jsonString;
      return {
        formatted: JSON.stringify(parsed, null, 2),
        error: null,
        isValid: true
      };
    } catch (error) {
      return {
        formatted: jsonString,
        error: `Invalid JSON: ${error.message}`,
        isValid: false
      };
    }
  }
}

export default new JsonService();
