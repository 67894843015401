import { apiClient } from '../AxiosConfig';
import {
  Systems,
  SystemsCredentials
} from '../../../constants/paths';

class VMService {
  async getVMCredentials(systemId) {
    const response = await apiClient.get(`${Systems}/getWithCredentials/${systemId}`);
    return response.data.result;
  }

  async updateVMCredentials(systemId, credentials) {
    const response = await apiClient.put(`${SystemsCredentials}/vm/${systemId}`, credentials);
    return response.data.result;
  }

  async getSystemById(systemId) {
    const response = await apiClient.get(`${Systems}/${systemId}`);
    return response.data.result;
  }

  async createVMCredentials(systemId, credentials) {
    const response = await apiClient.post(`${SystemsCredentials}/vm/${systemId}`, credentials);
    return response.data.result;
  }

  async deleteVMCredential(systemId, credentialId) {
    const response = await apiClient.delete(`${SystemsCredentials}/vm/${systemId}/${credentialId}`);
    return response.data.result;
  }
}

const vmService = new VMService();
export default vmService;
