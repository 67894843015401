import React, { useState, useEffect, lazy, Suspense, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '../../../contexts/ThemeContext';
import {
  fetchClientDetails,
  fetchSystems,
  fetchBranches,
  fetchVaData,
  fetchHolidays,
  fetchMatchedConversations,
  fetchUnknownMessages,
  fetchAiProcessingData,
  fetchOutboxMessages,
} from '../../../services/api/ApiFetch';
import ClientHeader from './components/ClientHeader';
import ClientTabs from './components/ClientTabs';
import LoadingSpinner from '../../../components/misc/LoadingSpinner';
import ErrorPage from '../../error/ErrorPage';
import { grabToken } from '../../../utils/api';

const DetailsTab = lazy(() => import('./components/DetailsTab'));
const LogsTab = lazy(() => import('./components/LogTab'));
const AiInfoTab = lazy(() => import('./components/AiInfoTab'));
const VaTab = lazy(() => import('./components/VaTab'));
const HolidaysTab = lazy(() => import('./components/HolidaysTab'));
const InboxTab = lazy(() => import('./components/InboxTabs'));
const OutboxTab = lazy(() => import('./components/OutboxTab'));

const ClientDetailsPage = () => {
  const [client, setClient] = useState({});
  const [systems, setSystems] = useState([]);
  const [branches, setBranches] = useState([]);
  const [vaData, setVaData] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [matchedConversations, setMatchedConversations] = useState([]);
  const [aiProcessingData, setAiProcessingData] = useState([]);
  const [outboundMessages, setOutboundMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('details');
  const [tabLoading, setTabLoading] = useState(false);

  const [unknownMessages, setUnknownMessages] = useState({
    list: [],
    totalElementsCount: 0,
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  const fetchEssentialData = useCallback(async () => {
    setLoading(true);
    try {
      const token = await grabToken();
      if (!token) {
        throw new Error('Authentication token not found');
      }
      const [clientData, systemsData, branchesData] = await Promise.all([
        fetchClientDetails(id, token),
        fetchSystems(id, token),
        fetchBranches(id, token),
      ]);
      setClient(clientData);
      setSystems(systemsData);
      setBranches(branchesData);
    } catch (error) {
      console.error('Error fetching essential data:', error);
      setError(error.message || 'Error fetching essential data');
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchEssentialData();
    }
  }, [id, fetchEssentialData]);

  const fetchVaDataForTab = useCallback(async () => {
    try {
      const token = await grabToken();
      if (!token) {
        throw new Error('Authentication token not found');
      }
      const data = await fetchVaData(id, token);
      setVaData(data);
    } catch (error) {
      console.error('Error fetching VA data:', error);
      setError(error.message || 'Error fetching VA data');
    }
  }, [id]);

  const fetchHolidaysData = useCallback(async () => {
    try {
      const token = await grabToken();
      if (!token) {
        throw new Error('Authentication token not found');
      }
      const data = await fetchHolidays(id, token);
      setHolidays(data);
    } catch (error) {
      console.error('Error fetching holidays data:', error);
      setError(error.message || 'Error fetching holidays data');
    }
  }, [id]);

  const fetchInboxData = useCallback(async (tabType) => {
    setTabLoading(true);
    try {
      const token = await grabToken();
      if (!token) {
        throw new Error('Authentication token not found');
      }

    switch (tabType) {
      case 'matched': {
        const matchedData = await fetchMatchedConversations(id, token);
        setMatchedConversations(Array.isArray(matchedData) ? matchedData : []);
        break;
      }
      case 'unknown': {
        const unknownData = await fetchUnknownMessages(id, token);
        setUnknownMessages(unknownData || { list: [], totalElementsCount: 0 });
        break;
      }
      case 'aiProcessing': {
        const aiData = await fetchAiProcessingData(id, token);
        setAiProcessingData(Array.isArray(aiData) ? aiData : []);
        break;
      }
      default:
        break;
    }
  } catch (error) {
    console.error(`Error in fetchInboxData for ${tabType}:`, error);
    setError(error.message || 'Error loading inbox data');
  } finally {
    setTabLoading(false);
  }
}, [id]);


  const fetchOutboxData = useCallback(async () => {
    try {
      const token = await grabToken();
      if (!token) {
        throw new Error('Authentication token not found');
      }
      const data = await fetchOutboxMessages(id, token);
      setOutboundMessages(data);
    } catch (error) {
      console.error('Error fetching outbox data:', error);
      setError(error.message || 'Error fetching outbox data');
    }
  }, [id]);

  const fetchTabData = useCallback(async (tab, subTab) => {
    setTabLoading(true);
    try {
      const token = await grabToken();
      if (!token) {
        throw new Error('Authentication token not found');
      }

      switch (tab) {
        case 'inbox':
          await fetchInboxData(subTab);
          break;
        case 'va':
          await fetchVaDataForTab();
          break;
        case 'holidays':
          await fetchHolidaysData();
          break;
        case 'outbox':
          await fetchOutboxData();
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(`Error fetching data for tab ${tab}:`, error);
      setError(error.message || `Error fetching data for tab ${tab}`);
    } finally {
      setTabLoading(false);
    }
  }, [fetchInboxData, fetchVaDataForTab, fetchHolidaysData, fetchOutboxData]);

  useEffect(() => {
    const subTab = activeTab === 'inbox' ? 'matched' : null;
    fetchTabData(activeTab, subTab);
  }, [activeTab, fetchTabData]);

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorPage message={error} isDarkMode={isDarkMode} />;

  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className="container mx-auto p-6">
        <ClientHeader navigate={navigate} isDarkMode={isDarkMode} clientName={client.name} />
        <ClientTabs activeTab={activeTab} setActiveTab={setActiveTab} />

        {tabLoading && <LoadingSpinner />}

        <Suspense fallback={<LoadingSpinner />}>
          <div>
            {activeTab === 'details' && (
              <DetailsTab client={client} systems={systems} branches={branches} isDarkMode={isDarkMode} />
            )}
            {activeTab === 'logs' && <LogsTab />}
            {activeTab === 'aiInfo' && (
              <AiInfoTab client={client} setClient={setClient} isDarkMode={isDarkMode} />
            )}
            {activeTab === 'va' && <VaTab vaData={vaData} isDarkMode={isDarkMode} navigate={navigate} />}
            {activeTab === 'holidays' && (
              <HolidaysTab
                holidays={holidays}
                setHolidays={setHolidays}
                isDarkMode={isDarkMode}
                clientId={id}
              />
            )}
            {activeTab === 'inbox' && (
              <InboxTab
                matchedConversations={matchedConversations}
                unknownMessages={unknownMessages}
                aiProcessingData={aiProcessingData}
                isDarkMode={isDarkMode}
              />
            )}
            {activeTab === 'outbox' && (
              <OutboxTab outboundMessages={outboundMessages} isDarkMode={isDarkMode} />
            )}
          </div>
        </Suspense>
      </div>
    </div>
  );
};

export default ClientDetailsPage;
