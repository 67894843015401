import React from 'react';
import PropTypes from 'prop-types';

const PageContainer = ({ children }) => {
  return (
    <div className="w-full h-full overflow-hidden">
      <div className="flex flex-col h-full">{children}</div>
    </div>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContainer;

