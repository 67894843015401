import React from 'react';
import { Form, Input } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import BaseDetailsForm from '../../../../components/basic/BaseDetailsForm.jsx';
import "../../../../styles/common.css";

const SystemDetailsForm = ({
  system,
  companyName,
  isEditing,
  onSubmit,
  onCancel,
  onEdit,
  isDarkMode
}) => {
  const formContent = (
    <>
      <Form.Item
        label={<span className="component-label">Company Name</span>}
        className="component-form-item"
      >
        <Input
          value={companyName}
          disabled
          className={`component-input ${isDarkMode ? 'dark-mode' : ''}`}
        />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">Name</span>}
        name="name"
        rules={[{ required: true, message: 'Name is required' }]}
        className="component-form-item"
      >
        <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">Description</span>}
        name="description"
        className="component-form-item"
      >
        <Input.TextArea
          className={`component-input ${isDarkMode ? 'dark-mode' : ''}`}
          rows={3}
        />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">IP Address</span>}
        name="ipAddress"
        rules={[{ required: true, message: 'IP Address is required' }]}
        className="component-form-item"
      >
        <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">Database Username</span>}
        name="databaseUserName"
        rules={[{ required: true, message: 'Database Username is required' }]}
        className="component-form-item"
      >
        <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">Database Password</span>}
        name="databasePassword"
        rules={[{ required: true, message: 'Database Password is required' }]}
        className="component-form-item"
      >
        <Input.Password
          className={`component-input ${isDarkMode ? 'dark-mode' : ''}`}
          iconRender={(visible) => (
            <span className="component-password-icon">
              {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            </span>
          )}
        />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">Foreign Key</span>}
        name="foreignKey"
        className="component-form-item"
      >
        <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">Batch Notify Email</span>}
        name="batchNotify"
        className="component-form-item"
      >
        <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
      </Form.Item>
    </>
  );

  return (
    <BaseDetailsForm
      title="System Details"
      isEditing={isEditing}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onEdit={onEdit}
      isDarkMode={isDarkMode}
      initialValues={system}
    >
      {formContent}
    </BaseDetailsForm>
  );
};

SystemDetailsForm.propTypes = {
  system: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    ipAddress: PropTypes.string,
    databaseUserName: PropTypes.string,
    databasePassword: PropTypes.string,
    foreignKey: PropTypes.string,
    batchNotify: PropTypes.string
  }).isRequired,
  companyName: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired
};

export default SystemDetailsForm;
