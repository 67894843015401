import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '../../../contexts/ThemeContext';
import { notification } from 'antd';
import SystemService from '../../../services/api/calls/SystemService';
import JsonService from '../../../services/misc/JsonService';
import CompanyService from '../../../services/api/calls/CompanyService';
import ErrorPage from '../../error/ErrorPage';
import LoadingSpinner from '../../../components/misc/LoadingSpinner';
import usePrompt from '../../../hooks/usePrompt';
import SystemContracts from './components/SystemContractComponent';
import SystemDetailsForm from './components/SystemDetailsForm';
import VMCredentialsGrid from './components/VmCredentialsGrid';
import { JsonEditor } from '../../../components/misc/JsonEditor';
import {
  FormOutlined,
  AppstoreOutlined,
  DesktopOutlined,
  FileTextOutlined
} from '@ant-design/icons';
import {
  TabNavigation,
  NavigationHeader,
  useFormHandlers,
  handleBackNavigation,
  getCommonStyles,
  confirmNavigation,
  handleApiError
} from '../../../components/helpers/CommonHelper';

const INITIAL_SYSTEM_STATE = {
  databasePassword: '',
  id: '',
  companyId: '',
  name: '',
  description: '',
  guid: '',
  batchNotify: '',
  processMotcheck: false,
  foreignKey: '',
  type: '',
  ipAddress: '',
  databaseUserName: '',
  properties: '',
  state: '',
};

const SystemDetailsPage = () => {
  const [system, setSystem] = useState(INITIAL_SYSTEM_STATE);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('details');
  const [logs] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [editorHeight] = useState('720px');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [editedJsonString, setEditedJsonString] = useState('{}');
  const [savedJsonString, setSavedJsonString] = useState('{}');
  const [isJsonValid, setIsJsonValid] = useState(false);
  const [jsonError, setJsonError] = useState(null);

  const originalValues = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const { containerClass } = getCommonStyles(isDarkMode);

  const tabs = [
    { id: 'details', label: 'Details', icon: <FormOutlined /> },
    { id: 'productguids', label: 'Product GUIDs', icon: <AppstoreOutlined /> },
    { id: 'virtualmachines', label: 'Virtual Machines', icon: <DesktopOutlined /> },
    { id: 'logs', label: 'Logs', icon: <FileTextOutlined /> }
  ];

  const {
    isEditing,
    setErrors,
    setIsEditing,
    handleEditClick,
    handleCancelEdit,
  } = useFormHandlers({
    initialData: system,
    fetchDetails: () => loadSystemDetails(id),
    onSave: (name, value) => {
      setSystem(prev => ({ ...prev, [name]: value }));
      setHasUnsavedChanges(true);
    },
    validateFields: SystemService.validateFields,
    hasUnsavedChanges
  });

  const loadSystemDetails = async (systemId) => {
    try {
      setLoading(true);
      setError(null);

      const { systemData, jsonData } = await SystemService.getSystemDetails(systemId);
      setSystem(systemData);
      setEditedJsonString(jsonData.formatted);
      setSavedJsonString(jsonData.formatted);
      setJsonError(jsonData.error);
      setIsJsonValid(jsonData.isValid);

      if (systemData.companyId) {
        const companyName = await CompanyService.getCompanyName(systemData.companyId);
        setCompanyName(companyName);
      }

      return systemData;
    } catch (err) {
      const errorMessage = handleApiError(err);
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      loadSystemDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (system && !originalValues.current) {
      originalValues.current = {
        ...system,
        jsonString: editedJsonString,
      };
    }
  }, [system, editedJsonString]);

  usePrompt('You have unsaved changes. Are you sure you want to leave?', hasUnsavedChanges && isEditing);

  const handleJsonChange = (value) => {
    const result = JsonService.parseAndFormat(value);
    setEditedJsonString(result.formatted);
    setJsonError(result.error);
    setIsJsonValid(result.isValid);
    setHasUnsavedChanges(true);
  };

  const handleValidateClick = () => {
    const result = JsonService.validateJson(editedJsonString);
    setJsonError(result.error);
    setIsJsonValid(result.isValid);

    if (result.isValid) {
      setSavedJsonString(result.formatted);
      notification.success({
        message: 'JSON is valid',
        description: 'The JSON has been validated.',
      });
    } else {
      notification.error({
        message: 'Invalid JSON',
        description: result.error,
      });
    }
  };

  const handleBack = () => {
    confirmNavigation(hasUnsavedChanges, (shouldNavigate) => {
      if (shouldNavigate) {
        handleBackNavigation(navigate, location, '/client-admin/systems');
      }
    });
  };

  const handleSubmit = async (formValues) => {
    setLoading(true);

    const updatedSystem = {
      ...system,
      ...formValues,
      properties: savedJsonString
    };

    const formErrors = SystemService.validateFields(updatedSystem);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setLoading(false);
      return;
    }

    try {
      await SystemService.updateSystem(updatedSystem.id, updatedSystem);

      setSystem(updatedSystem);
      setHasUnsavedChanges(false);
      setIsEditing(false);

      notification.success({
        message: 'System was updated',
      });

      await loadSystemDetails(updatedSystem.id);
    } catch (err) {
      const errorMessage = handleApiError(err);
      setError(errorMessage);
      notification.error({
        message: 'System update failed',
        description: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  const renderDetailsTab = () => (
    <div className="space-y-4">
      <div className="flex space-x-4">
        <div className="w-1/2">
          <SystemDetailsForm
            system={system}
            companyName={companyName}
            isEditing={isEditing}
            onSubmit={handleSubmit}
            onCancel={handleCancelEdit}
            onEdit={handleEditClick}
            isDarkMode={isDarkMode}
          />
        </div>
        <div className="w-1/2">
          <JsonEditor
            editedJsonString={editedJsonString}
            handleJsonChange={handleJsonChange}
            isEditing={isEditing}
            isDarkMode={isDarkMode}
            jsonError={jsonError}
            isJsonValid={isJsonValid}
            handleValidateClick={handleValidateClick}
            editorHeight={editorHeight}
            onSave={handleSubmit}
            onCancel={handleCancelEdit}
            onEdit={handleEditClick}
            saveDisabled={!isJsonValid}
          />
        </div>
      </div>
    </div>
  );

  const renderLogsTab = () => (
    <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden p-4">
      <h2 className="text-xl font-semibold mb-4">System Logs</h2>
      {logs.map((log, index) => (
        <div key={index} className="mb-2">
          <span className="font-medium">{new Date(log.timestamp).toLocaleString()}: </span>
          <span>{log.message}</span>
        </div>
      ))}
    </div>
  );

  const renderProductGuidsTab = () => (
    <SystemContracts
      systemId={system.id}
      companyId={system.companyId}
      isDarkMode={isDarkMode}
    />
  );

  const renderVMTab = () => (
    <VMCredentialsGrid
      systemId={system.id}
      isDarkMode={isDarkMode}
    />
  );

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorPage isDarkMode={isDarkMode} errorMessage={error} />;

  return (
    <div className={containerClass}>
      <div className="container mx-auto p-6">
        <NavigationHeader
          title={`System Details | ${system.name}`}
          onBackClick={handleBack}
          isDarkMode={isDarkMode}
        />

        <TabNavigation
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={setActiveTab}
          isDarkMode={isDarkMode}
        />

        {activeTab === 'details' ? renderDetailsTab() :
         activeTab === 'productguids' ? renderProductGuidsTab() :
         activeTab === 'virtualmachines' ? renderVMTab() :
         activeTab === 'logs' ? renderLogsTab() :
         <div>No content available</div>}
      </div>
    </div>
  );
};

export default SystemDetailsPage;
