import { getPageNumbers } from "../components/helpers/CommonHelper.jsx";
import { useState } from 'react';

export const usePagination = (data, itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (action) => {
    if (action === 'prev') {
      setCurrentPage((p) => Math.max(p - 1, 1));
    } else if (action === 'next') {
      setCurrentPage((p) => Math.min(p + 1, totalPages));
    } else {
      setCurrentPage(action);
    }
  };

  return {
    currentPage,
    setCurrentPage,
    totalPages,
    indexOfFirstItem,
    indexOfLastItem,
    currentItems,
    paginate,
    getPageNumbers
  };
};
