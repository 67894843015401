import React from 'react';
import { useTheme } from '../../../../contexts/ThemeContext';
import PropTypes from 'prop-types';
import { TabNavigation } from '../../../../components/helpers/CommonHelper.jsx';
import {
  FormOutlined,
  FileTextOutlined,
  RobotOutlined,
  CalendarOutlined,
  InboxOutlined,
  SendOutlined
} from '@ant-design/icons';
import { CpuChipIcon } from '@heroicons/react/24/outline';

const ClientTabs = ({ activeTab, setActiveTab }) => {
  const { isDarkMode } = useTheme();

  const tabs = [
    { id: 'details', label: 'Details', icon: <FormOutlined /> },
    { id: 'aiInfo', label: 'AI Info', icon: <CpuChipIcon className="w-4 h-4" /> },
    { id: 'va', label: 'Virtual Assistants', icon: <RobotOutlined /> },
    { id: 'holidays', label: 'Holidays', icon: <CalendarOutlined /> },
    { id: 'inbox', label: 'Inbox', icon: <InboxOutlined /> },
    { id: 'outbox', label: 'Outbox', icon: <SendOutlined /> },
    { id: 'logs', label: 'Logs', icon: <FileTextOutlined /> }
  ];

  return (
    <div className={isDarkMode ? 'dark' : ''}>
      <TabNavigation
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        isDarkMode={isDarkMode}
      />
    </div>
  );
};

ClientTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired
};

export default ClientTabs;
