export const ApiBase = process.env.REACT_APP_API_BASE_URL

export const Actions = `${ApiBase}/Actions`;

export const ChangeLogs = `${ApiBase}/ChangeLogs`;

export const Conversations = `${ApiBase}/Conversations`;

export const ConversationHolidays = `${ApiBase}/ConversationHolidays`;
export const ConversationWorkingSchedule = `${ApiBase}/ConversationWorkingSchedule`;

export const ConversationTemplates = `${ApiBase}/MessageTemplates`;
export const GenericConversationTemplates = `${ApiBase}/GenericMessageTemplates`;

export const Customers = `${ApiBase}/customers`;

export const ConversationContacts = `${ApiBase}/ConversationContacts`;

export const Messages = `${ApiBase}/Messages`;
export const MessagesIncoming = `${Messages}/incoming`;
export const MessagesOutgoing = `${Messages}/outgoing`;

export const Branches = `${ApiBase}/branches`;
export const BranchesDistributionEmails = `${ApiBase}/BranchesDistributionEmails`;
export const MyBranches = `${Branches}/my`;
export const BranchesSelectList = `${Branches}/selectList`;
export const BranchesFilter = `${Branches}/filter`;
export const BranchesGetForMyCompany = `${Branches}/getForMyCompany`;

export const Companies = `${ApiBase}/companies`;
export const MyCompanies = `${Companies}/my`;
export const MyWorkspace = `${Companies}/workspace`;
export const CompaniesSelectList = `${Companies}/selectList`;

export const EmailProviders = `${ApiBase}/EmailProviders`;
export const Events = `${ApiBase}/Events`;

export const Contracts = `${ApiBase}/contracts`;
export const ContractsSelectList = `${Contracts}/selectList`;

export const PredictionModels = `${ApiBase}/predictionModels`;

export const CustomerData = `${ApiBase}/customerData`;

export const Notifications = `${ApiBase}/notifications`;
export const LatestNotifications = `${Notifications}/latest`;
export const NotificationsRead = `${Notifications}/read`;
export const NotificationsReadAll = `${Notifications}/readAll`;
export const NotificationsDeleteAllRead = `${Notifications}/deleteAllRead`;

export const ProcBatches = `${ApiBase}/procBatches`;
export const ProcBatchesFilter = `${ProcBatches}/filter`;

export const Products = `${ApiBase}/products`;
export const ProductsSelectList = `${Products}/selectList`;

export const Systems = `${ApiBase}/systems`;
export const SystemsSelectList = `${Systems}/selectList`;
export const SystemsCredentials = `${Systems}/credentials`;
export const SystemsTestSftp = `${Systems}/testSftp`;

export const SystemsWithOpenProcBatches = `${Systems}/withOpenProcBatches`;

export const UsersApi = `${ApiBase}/users`;
export const UsersUpdatePermissions = `${UsersApi}/updatePermissions`;

export const VirtualAssistants = `${ApiBase}/VirtualAssistants`;
