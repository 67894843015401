import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getProductName } from '../../constants/ProductTypeConverter.jsx';
import { fetchCustomersData, fetchMessageContent } from '../../services/api/ApiFetch';
import { grabToken } from '../../utils/api';
import { useTheme } from '../../contexts/ThemeContext';
import Header from '../../components/layouts/HeaderWithoutSideBar';
import LoadingSpinner from '../../components/misc/LoadingSpinner';
import { sanitizeEmailContent } from '../../utils/emailSanitizer';
import ErrorPage from '../error/ErrorPage';
import formatDate from '../../utils/formatDate';
import {
  SmileIcon,
  FrownIcon,
  MessageCircleIcon,
  Waypoints,
} from 'lucide-react';
import TagBar from "../../components/misc/TagBar.jsx"


const CustomTag = React.memo(function CustomTag({ label, value }) {
  return (
    <span className={`inline-block px-2 py-1 rounded-full text-xs font-semibold mr-2 mb-2
      bg-gray-200 text-gray-700 dark:bg-gray-600 dark:text-white`}
    >
      {label}: {value}
    </span>
  );
});

CustomTag.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

CustomTag.displayName = 'CustomTag';

const DataTable = ({ title, data, loading }) => (
  <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-2">
    <h2 className="bg-blue-500 dark:bg-blue-700 text-white px-2 py-1 rounded-t-lg font-semibold">
      {title}
    </h2>
    <div className="p-2">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <table className="min-w-full">
          <tbody>
            {data.map((item) => (
              <tr key={item.id} className="border-b border-gray-200 dark:border-gray-700">
                <td className="px-2 py-1 font-medium">{item.attribute}</td>
                <td className="px-2 py-1">{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  </div>
);

DataTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      attribute: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  loading: PropTypes.bool,
};

function EmailContent({ email, isDarkMode }) {
  if (!email || typeof isDarkMode !== 'boolean') return null;

  const formatDateTime = (isoString) => {
    if (!isoString) return 'N/A';
    return new Date(isoString).toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  };

  const getSenderInfo = () => {
    if (email.createdUser) {
      return `${email.createdUser.firstName} ${email.createdUser.lastName} (${email.createdUser.email})`;
    }
    return email.senderEmail || 'System Generated';
  };

  const getContent = (content) => {
    if (!content) return 'No content available';

    let sanitized = sanitizeEmailContent(content);

    sanitized = sanitized.replace(/<img[^>]*>/g, '');

    sanitized = sanitized.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');

    sanitized = sanitized.replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, '');

    let mainContent = sanitized;
    if (sanitized.includes('wrote:')) {
      const parts = sanitized.split(/On.*wrote:/);
      mainContent = parts[0];
    }

    return `
      <div class="email-message" style="color: ${isDarkMode ? 'white' : 'black'};">
        ${mainContent}
      </div>
    `;
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden mb-4">
      <h2 className="bg-blue-500 dark:bg-blue-700 text-white px-4 py-2 font-semibold">
        {email.subject || 'No Subject'}
      </h2>

      <div className="p-4">
        <div className="flex flex-wrap gap-2 mb-4 bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
          <CustomTag label="From" value={getSenderInfo()} />
          <CustomTag label="To" value={email.toRecipients?.join(', ') || 'N/A'} />
          <CustomTag label="Created" value={formatDateTime(email.createdDate)} />
          <CustomTag label="Sent" value={formatDateTime(email.sentOnUtc)} />
          {email.cc?.length > 0 && <CustomTag label="CC" value={email.cc.join(', ')} />}
          {email.bcc?.length > 0 && <CustomTag label="BCC" value={email.bcc.join(', ')} />}
          {email.template && <CustomTag label="Template" value={email.template.name || 'N/A'} />}
        </div>

        <div className="mt-4">
          <div
            className={`prose max-w-none p-4 bg-white dark:bg-gray-800 rounded-lg ${
              isDarkMode ? 'text-white' : 'text-black'
            }`}
            dangerouslySetInnerHTML={{ __html: getContent(email.content) }}
          />
        </div>
      </div>
    </div>
  );
}

EmailContent.propTypes = {
  email: PropTypes.shape({
    id: PropTypes.string,
    subject: PropTypes.string,
    senderEmail: PropTypes.string,
    toRecipients: PropTypes.arrayOf(PropTypes.string),
    cc: PropTypes.arrayOf(PropTypes.string),
    bcc: PropTypes.arrayOf(PropTypes.string),
    createdDate: PropTypes.string,
    sentOnUtc: PropTypes.string,
    content: PropTypes.string,
    template: PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.number,
    }),
    createdUser: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }).isRequired,
  isDarkMode: PropTypes.bool.isRequired,
};

function EmailContentPage() {
  const { contactId } = useParams();
  const { isDarkMode } = useTheme();
  const [contactId1, systemIdPart] = contactId.split("&s=");
  const systemId = systemIdPart || null;
  const [clientData, setClientData] = useState(null);
  const [emailMessages, setEmailMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [emailStats, setEmailStats] = useState({
    sent: 0,
    received: 0,
    lastSentiment: 'neutral',
  });

  const formatCustomerData = (data) => {
    const customer = data?.customer;
    if (!customer) return [];
    return [
      {
        id: 1,
        attribute: 'Full Name',
        value: `${customer?.title ?? ''} ${customer?.firstName ?? ''} ${customer?.lastName ?? ''}`.trim() || 'N/A'
      },
      {
        id: 2,
        attribute: 'Personal Email',
        value: customer?.emailHome ?? 'N/A'
      },
      {
        id: 3,
        attribute: 'Work Email',
        value: customer?.emailWork ?? 'N/A'
      },
      {
        id: 4,
        attribute: 'Home Phone',
        value: customer?.telHome ?? 'N/A'
      },
      {
        id: 5,
        attribute: 'Mobile',
        value: customer?.mobHome ?? 'N/A'
      },
      {
            id: 6,
            attribute: 'Customer ID',
            value: customer?.foreignKey ? (
              <a
                href={`/customer-reporting/${systemId}_${customer.foreignKey}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {customer.foreignKey}
              </a>
            ) : (
              'N/A'
            ),
          }
        ];
      };
  const formatCrmData = (data) => {
    const branch = data?.branch;
    if (!branch) return [];
    return [
      // { id: 1, attribute: 'Company System', value: data?.additionalData?.ContractSystemGUID ?? 'N/A' },
      { id: 1, attribute: 'Branch', value: branch?.name ?? 'N/A' },
      { id: 2, attribute: 'Reference', value: branch?.reference || branch?.reference || 'N/A' },
    ];
  };

  const formatVehicleData = (data) => {
    const vehicle = data?.vehicle;
    const additionalData = data?.additionalData;
    if (!vehicle) return [];
    return [
      { id: 1, attribute: 'Registration', value: vehicle?.regNumber ?? 'N/A' },
      { id: 2, attribute: 'VIN', value: vehicle?.vin ?? 'N/A' },
      { id: 3, attribute: 'Make', value: vehicle?.make ?? 'N/A' },
      { id: 4, attribute: 'Model', value: vehicle?.model ?? 'N/A' },
      { id: 5, attribute: 'Next MOT Date', value: formatDate(additionalData?.MOTDate) ?? 'N/A' },
      { id: 6, attribute: 'Next Service Date', value: formatDate(additionalData?.NextServiceDate) ?? 'N/A' }
    ];
  };

  const formatBranchData = (data) => {
    const branch = data?.branch;
    if (!branch) return [];
    return [
      { id: 1, attribute: 'Branch', value: branch?.name ?? 'N/A' },
      { id: 2, attribute: 'Branch Code', value: data?.branchId ?? 'N/A' },
      { id: 3, attribute: 'Address', value: branch?.address1 ?? 'N/A' },
      { id: 4, attribute: 'Town', value: branch?.town ?? 'N/A' },
      { id: 5, attribute: 'County', value: branch?.county ?? 'N/A' },
      { id: 6, attribute: 'Postcode', value: branch?.zipPostCode ?? 'N/A' },
      { id: 7, attribute: 'Telephone', value: branch?.tel ?? 'N/A' },
      { id: 8, attribute: 'Email', value: branch?.distributionEmails?.[0]?.emails?.join(', ') ?? 'N/A' }
    ];
  };

  const formatConversationData = (data) => {
    const conversation = data?.conversation;
    if (!conversation) return [];
    return [
      { id: 1, attribute: 'Conversation', value: conversation?.name ?? 'N/A' },
      { id: 2, attribute: 'Product', value: getProductName(conversation?.conversationProduct) },
      { id: 4, attribute: 'Created Date', value: formatDate(data?.createdDate) ?? 'N/A' },
      { id: 5, attribute: 'Updated Date', value: formatDate(data?.updatedDate) ?? 'N/A' }
    ];
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!contactId1) {
        setError('No contact ID provided');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const token = await grabToken();
        const lowerCaseContactId = contactId1.toLowerCase();
        const refRemovedId = lowerCaseContactId.split("_")[0];
        const conId = refRemovedId || lowerCaseContactId;
        if (!token) {
          throw new Error('Authentication token not found');
        }

        let data, emailData;
        try {
          [data, emailData] = await Promise.all([
            fetchCustomersData(conId, token),
            fetchMessageContent(conId, token),
          ]);
        } catch (apiError) {
          if (apiError.response?.status === 400) {
            throw new Error(`Invalid request: ${apiError.response?.data?.message || 'Please check the contact ID'}`);
          }
          throw apiError;
        }

        if (!data) {
          setError('No customer data received');
          setLoading(false);
          return;
        }

        const finalEmailData = Array.isArray(emailData) ? emailData : [];
        const sentCount = finalEmailData.filter(email =>
          email?.template || !email?.senderEmail || email?.createdUser
        ).length;

        const receivedCount = finalEmailData.filter(email =>
          email?.senderEmail && !email?.template && !email?.createdUser
        ).length;

        setEmailStats({
          sent: sentCount,
          received: receivedCount,
          lastSentiment: 'neutral'
        });

        setClientData(data);
        setEmailMessages(finalEmailData);
        setError(null);
      } catch (err) {
        setError(err?.message || 'Failed to fetch data');
        setClientData(null);
        setEmailMessages([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [contactId1]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) return <ErrorPage errorMessage={error} isDarkMode={isDarkMode} showNote={true} />;

  if (!clientData) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-center bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
          <p>No client data available</p>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`min-h-screen flex flex-col ${
        isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'
      }`}
    >
      <Header />
      <div className="container mx-auto px-4 py-6 pb-16 flex-grow">
        <div className="flex flex-col md:flex-row gap-6">
          <div className="w-full md:w-1/3 flex flex-col gap-6">
            <DataTable
              title="CRM Details"
              data={formatCrmData(clientData)}
              loading={loading}
            />
            <DataTable
              title="Customer Details"
              data={formatCustomerData(clientData)}
              loading={loading}
            />
            <DataTable
              title="Vehicle Information"
              data={formatVehicleData(clientData)}
              loading={loading}
            />
            <DataTable
              title="Branch Information"
              data={formatBranchData(clientData)}
              loading={loading}
            />
            <DataTable
              title="Conversation Details"
              data={formatConversationData(clientData)}
              loading={loading}
            />
          </div>

          <div className="w-full md:w-2/3 flex flex-col gap-6">
            <div className="flex flex-col md:flex-row gap-6">
              <div className="flex-1 bg-white dark:bg-gray-800 shadow rounded-lg p-4">
                <div className="flex items-center">
                  <div className="bg-blue-500 text-white rounded-full h-12 w-12 flex items-center justify-center font-bold mr-4">
                    <MessageCircleIcon size={24} />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">Messages</h3>
                    <p className="text-sm capitalize">{emailStats.sent}</p>
                  </div>
                </div>
              </div>

              <div className="flex-1 bg-white dark:bg-gray-800 shadow rounded-lg p-4">
                <div className="flex items-center">
                  <div
                    className={`${
                      emailStats.lastSentiment === 'positive'
                        ? 'bg-green-500'
                        : emailStats.lastSentiment === 'negative'
                        ? 'bg-red-500'
                        : 'bg-gray-500'
                    } text-white rounded-full h-12 w-12 flex items-center justify-center font-bold mr-4`}
                  >
                    {emailStats.lastSentiment === 'positive' ? (
                      <SmileIcon size={24} />
                    ) : emailStats.lastSentiment === 'negative' ? (
                      <FrownIcon size={24} />
                    ) : (
                      '😐'
                    )}
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">Last Sentiment</h3>
                    <p className="text-sm capitalize">{emailStats.lastSentiment}</p>
                  </div>
                </div>
              </div>

              <div className="flex-1 bg-white dark:bg-gray-800 shadow rounded-lg p-4">
                <div className="flex items-center">
                  <div className="bg-blue-500 text-white rounded-full h-12 w-12 flex items-center justify-center font-bold mr-4">
                    <Waypoints size={24} />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">Status</h3>
                    <p className="text-sm capitalize">{getProductName(clientData.updatedUsabilityStatus)}</p>
                  </div>
                </div>
              </div>
            </div>

            {clientData?.updatedUsabilityStatuses && (
              <TagBar tags={clientData.updatedUsabilityStatuses} />
            )}

            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-4">
              <h2 className="bg-blue-500 dark:bg-blue-700 text-white px-4 py-2 rounded-t-lg font-semibold mb-4">
                Email Messages
              </h2>

              <div className="space-y-4">
                {emailMessages && emailMessages.length > 0 ? (
                  emailMessages
                    .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
                    .map((email) => (
                      <EmailContent
                        key={email.id || Math.random().toString(36)}
                        email={email}
                        isDarkMode={isDarkMode}
                      />
                    ))
                ) : (
                  <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 text-center">
                    <p className="text-gray-500 dark:text-gray-400">
                      No email messages available
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="fixed bottom-0 left-0 right-0 bg-gray-200 dark:bg-gray-700 text-center p-2 text-sm z-50">
        © {new Date().getFullYear()} AI-Assistant. All rights reserved.
      </footer>
    </div>
  );
}

export default EmailContentPage;

