import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '../../contexts/ThemeContext';
import ErrorPage from '../../pages/error/ErrorPage';
import LoadingSpinner from '../misc/LoadingSpinner';
import PageContainer from './PageContainer';
import PageHeader from './PageHeader';
import FilterableTable from './FilterableTable';
import PaginatedFooter from './PaginatedFooter';
import { getPageNumbers } from "../helpers/CommonHelper.jsx";
import { usePagination } from '../../hooks/usePagination';

const BaseTablePage = ({
  title,
  columns,
  fetchData,
  onRowClick,
  defaultFilters,
  filterData,
  initialItemsPerPage = 10
}) => {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);

  const { isDarkMode } = useTheme();

  const filteredData = filterData ? filterData(data, filters) : data;

  const {
    currentPage,
    setCurrentPage,
    totalPages,
    indexOfFirstItem,
    indexOfLastItem,
    currentItems,
    paginate
  } = usePagination(filteredData, itemsPerPage);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const result = await fetchData();
      setData(Array.isArray(result) ? result : result?.list || []);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (accessor, value) => {
    setFilters((prev) => ({
      ...prev,
      [accessor]: value,
    }));
    setCurrentPage(1);
  };

  const handleItemsPerPageChange = (val) => {
    setItemsPerPage(val);
    setCurrentPage(1);
  };

  if (error) {
    return (
      <ErrorPage
        isDarkMode={isDarkMode}
        errorMessage={error.message || error}
        errorCode=""
      />
    );
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <PageContainer>
      <PageHeader title={title} />

      <div className="flex-grow overflow-auto">
        <FilterableTable
          columns={columns}
          data={currentItems}
          filters={filters}
          onFilterChange={handleFilterChange}
          onRowClick={onRowClick}
          isDarkMode={isDarkMode}
          noDataText="No data available"
        />
      </div>

      <PaginatedFooter
        currentPage={currentPage}
        totalPages={totalPages}
        indexOfFirstItem={indexOfFirstItem}
        indexOfLastItem={indexOfLastItem}
        filteredDataLength={filteredData.length}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
        onPageChange={paginate}
        getPageNumbers={getPageNumbers}
      />
    </PageContainer>
  );
};

BaseTablePage.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string.isRequired,
    accessor: PropTypes.string.isRequired,
    render: PropTypes.func
  })).isRequired,
  fetchData: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  defaultFilters: PropTypes.object.isRequired,
  filterData: PropTypes.func,
  initialItemsPerPage: PropTypes.number
};

export default BaseTablePage;
