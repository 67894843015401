import { apiClient } from '../AxiosConfig';
import { Branches } from '../../../constants/paths.jsx';

class BranchService {
  async getBranches(clientId) {
    const response = await apiClient.get(`${Branches}/selectList/${clientId}`);
    return response.data.result;
  }

  async updateBranch(branchId, branchData) {
    const response = await apiClient.put(`${Branches}/${branchId}`, branchData);
    return response.data;
  }

  async getBranchDetails(branchId) {
    const response = await apiClient.get(`${Branches}/${branchId}`);
    return response.data.result;
  }
}

export default new BranchService();
