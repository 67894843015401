import React, { useState, useEffect } from 'react';
import { Table, notification, BackTop } from "antd";
import PropTypes from 'prop-types';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import vmService from '../../../../services/api/calls/VmService';
import CopyButton from '../../../../components/buttons/CopyButton.jsx';
import "../../../../styles/common.css";

const VMCredentialsGrid = ({ systemId, isDarkMode }) => {
  const [credentials, setCredentials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState({});

  const fetchCredentials = async () => {
    setLoading(true);
    try {
      const result = await vmService.getVMCredentials(systemId);
      const vmCreds = result.credentials.filter(cred => cred.type === 1);
      setCredentials(vmCreds);

      const initialVisibility = {};
      vmCreds.forEach((_, index) => {
        initialVisibility[index] = false;
      });
      setPasswordVisibility(initialVisibility);
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Failed to fetch credentials'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (systemId) {
      fetchCredentials();
    }
  }, [systemId]);

  const togglePasswordVisibility = (index) => {
    setPasswordVisibility(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const transformDataForVerticalDisplay = (credential, index) => {
    return [
      { key: 'hostname', label: 'Hostname', value: credential.localHost },
      { key: 'address', label: 'Address', value: credential.address },
      { key: 'username', label: 'Username', value: credential.userName },
      {
        key: 'password',
        label: 'Password',
        value: credential.password,
        isPassword: true,
        credentialIndex: index
      },
      { key: 'link', label: 'Link', value: credential.link },
      { key: 'desktopUser', label: 'Desktop User', value: credential.desktopUser },
      { key: 'domain', label: 'Domain', value: credential.domain },
    ];
  };

  const verticalColumns = [
    {
      title: 'Property',
      dataIndex: 'label',
      key: 'label',
      width: '180px',
      render: (text) => (
        <span className="component-label">{text}</span>
      )
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text, record) => (
        <div className="vm-value-container">
          {record.isPassword ? (
            <>
              <span className="vm-value-text">
                {passwordVisibility[record.credentialIndex] ? text : '••••••••'}
              </span>
              <button
                onClick={() => togglePasswordVisibility(record.credentialIndex)}
                className="component-button component-button-secondary"
              >
                {passwordVisibility[record.credentialIndex] ?
                  <EyeInvisibleOutlined /> :
                  <EyeOutlined />
                }
              </button>
              <CopyButton textToCopy={text} className="component-button component-button-secondary" />
            </>
          ) : (
            <>
              <span className="vm-value-text">{text || '-'}</span>
              {text && <CopyButton textToCopy={text} className="component-button component-button-secondary" />}
            </>
          )}
        </div>
      ),
    }
  ];

  if (loading) {
    return (
      <div className={`component-container ${isDarkMode ? 'dark-mode' : ''}`}>
        <div className="component-loading">
          <Table
            loading={true}
            columns={verticalColumns}
            dataSource={[]}
            pagination={false}
            className="component-table"
          />
        </div>
      </div>
    );
  }

  const allCredentialsData = credentials.flatMap((credential, index) =>
    transformDataForVerticalDisplay(credential, index)
  );

  return (
    <div className={`component-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="component-content">
        <Table
          dataSource={allCredentialsData}
          columns={verticalColumns}
          pagination={false}
          size="middle"
          bordered={false}
          rowKey="key"
          loading={loading}
          className={`component-table ${isDarkMode ? 'dark-mode' : ''}`}
        />
        <BackTop>
          <div className="back-to-top-button">
            UP
          </div>
        </BackTop>
      </div>
    </div>
  );
};

VMCredentialsGrid.propTypes = {
  systemId: PropTypes.number.isRequired,
  isDarkMode: PropTypes.bool.isRequired
};

export default VMCredentialsGrid;
