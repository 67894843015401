import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { grabToken } from '../../../utils/api';
import ErrorPage from '../../error/ErrorPage';
import LoadingSpinner from '../../../components/misc/LoadingSpinner';
import { useTheme } from '../../../contexts/ThemeContext';
import PageContainer from '../../../components/basic/PageContainer';
import PageHeader from '../../../components/basic/PageHeader';
import FilterableTable from '../../../components/basic/FilterableTable';
import PaginatedFooter from '../../../components/basic/PaginatedFooter';
import { usePagination } from '../../../hooks/usePagination';

const BranchesDataPage = () => {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    id: '',
    companyName: '',
    name: '',
    branchId: '',
    tel: '',
    town: '',
    county: '',
    zipPostCode: '',
    state: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  useEffect(() => {
    (async () => {
      try {
        const token = await grabToken();
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await axios.get(`${apiBaseUrl}/Branches/filter`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
          params: { pageSize: 1000 },
        });

        const list = response?.data?.result?.list;
        if (Array.isArray(list)) {
          setData(list);
        } else {
          setError('Received data is not in the expected format');
        }
      } catch (err) {
        setError(
          err.response
            ? `Error: ${err.response.status} - ${err.response.data}`
            : err.message
        );
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const filteredData = data.filter((item) =>
    (item.id?.toString().toLowerCase().includes(filters.id.toLowerCase()) ?? false) &&
    (item.company?.name?.toLowerCase().includes(filters.companyName.toLowerCase()) ?? false) &&
    (item.name?.toLowerCase().includes(filters.name.toLowerCase()) ?? false) &&
    (item.company?.id?.toString().toLowerCase().includes(filters.branchId.toLowerCase()) ?? false) &&
    (item.tel?.toLowerCase().includes(filters.tel.toLowerCase()) ?? false) &&
    (item.town?.toLowerCase().includes(filters.town.toLowerCase()) ?? false) &&
    (item.county?.toLowerCase().includes(filters.county.toLowerCase()) ?? false) &&
    (item.zipPostCode?.toLowerCase().includes(filters.zipPostCode.toLowerCase()) ?? false) &&
    (item.state?.toString().includes(filters.state) ?? false)
  );

  const {
    currentPage,
    totalPages,
    indexOfFirstItem,
    indexOfLastItem,
    currentItems,
    paginate,
    getPageNumbers
  } = usePagination(filteredData, itemsPerPage);

  if (error) {
    return (
      <ErrorPage
        isDarkMode={isDarkMode}
        errorMessage={error.message || error}
        errorCode=""
      />
    );
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  const handleFilterChange = (accessor, value) => {
    setFilters((prev) => ({ ...prev, [accessor]: value }));
  };

  const handleRowClick = (row) => {
    navigate(`/client-admin/branches/${row.id}`);
  };

  const handleItemsPerPageChange = (val) => {
    setItemsPerPage(val);
  };

  const columns = [
    {
      header: 'Branch Name',
      accessor: 'name',
    },
    {
      header: 'Company',
      accessor: 'companyName',
      render: (row) => row.company?.name || '',
    },
    {
      header: 'Branch ID',
      accessor: 'branchId',
      render: (row) => row.company?.id || '',
    },
    { header: 'Town', accessor: 'town' },
    { header: 'County', accessor: 'county' },
    { header: 'Zip', accessor: 'zipPostCode' },
    {
      header: 'State',
      accessor: 'state',
      render: (row) => (
        <span
          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
            row.state === 0
              ? 'bg-green-100 text-green-800'
              : 'bg-red-100 text-red-800'
          }`}
        >
          {row.state === 0 ? 'Active' : 'Inactive'}
        </span>
      ),
    },
  ];

  return (
    <PageContainer>
      <PageHeader title="Client Admin | Branches">
        <Link
          to="/client-admin/branches/add"
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600
                     transition-colors duration-200"
        >
          Add New Branch
        </Link>
      </PageHeader>

      <div className="flex-grow overflow-auto">
        <FilterableTable
          columns={columns}
          data={currentItems}
          filters={filters}
          onFilterChange={handleFilterChange}
          onRowClick={handleRowClick}
          isDarkMode={isDarkMode}
          noDataText="No data available"
        />
      </div>

      <PaginatedFooter
        currentPage={currentPage}
        totalPages={totalPages}
        indexOfFirstItem={indexOfFirstItem}
        indexOfLastItem={indexOfLastItem}
        filteredDataLength={filteredData.length}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
        onPageChange={paginate}
        getPageNumbers={getPageNumbers}
      />
    </PageContainer>
  );
};

export default BranchesDataPage;
