import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { useTheme } from '../../../contexts/ThemeContext';
import { fetchConversation, updateConversation } from '../../../services/api/ApiFetch';
import { grabToken } from '../../../utils/api';
import ErrorPage from '../../error/ErrorPage.jsx';
import LoadingSpinner from '../../../components/misc/LoadingSpinner';

const ConversationDetailPage = () => {
  const [conversation, setConversation] = useState({
    id: '',
    name: '',
    note: '',
    conversationProduct: { id: 0, name: '' },
    evaluationMode: 0,
    isEnabled: false,
    startedOnUtc: '',
    virtualAssistant: { name: '' },
    state: 0,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('general');
  const [isEditing, setIsEditing] = useState(false);
  const [editedConversation, setEditedConversation] = useState({});
  const { id } = useParams();
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const fetchData = async (conversationId) => {
    setLoading(true);
    try {
      const token = await grabToken();
      const conversationData = await fetchConversation(conversationId, token);
      setConversation(conversationData);
      setEditedConversation(conversationData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditedConversation(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await grabToken();
      await updateConversation(id, editedConversation, token);
      setConversation(editedConversation);
      setIsEditing(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleBackClick = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate('/client-admin/client');
    }
  };

  const tabClass = (tabName) => `
    px-4 py-2 font-medium text-sm rounded-t-lg
    ${activeTab === tabName
      ? isDarkMode
        ? 'bg-gray-700 text-white'
        : 'bg-white text-blue-600'
      : isDarkMode
      ? 'bg-gray-600 text-gray-300 hover:bg-gray-500'
      : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
    }
  `;

  const renderField = (label, name, type = 'text', editable = true) => {
    const value = isEditing ? editedConversation[name] : conversation[name];
    const isUnchangeable = ['id', 'conversationProduct', 'virtualAssistant'].includes(name);

    return (
      <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600 last:border-b-0">
        <span className="text-sm font-medium text-gray-700 dark:text-gray-300">{label}</span>
        <div className="col-span-2">
          {isEditing && editable && !isUnchangeable ? (
            type === 'checkbox' ? (
              <input
                type="checkbox"
                name={name}
                checked={value}
                onChange={handleInputChange}
                className={`form-checkbox h-5 w-5 ${isDarkMode ? 'text-blue-600 bg-gray-700' : 'text-indigo-600'}`}
              />
            ) : (
              <input
                type={type}
                name={name}
                value={value}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
                  isDarkMode
                    ? 'bg-gray-700 border-gray-600 focus:border-blue-300 focus:ring-blue-200 text-white'
                    : 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200'
                }`}
              />
            )
          ) : (
            <span className="text-sm text-gray-900 dark:text-white">
              {type === 'checkbox' ? (value ? 'Yes' : 'No') :
               name === 'conversationProduct' ? value.name :
               name === 'virtualAssistant' ? value.name :
               name === 'startedOnUtc' ? format(new Date(value), "PPP p") :
               value}
            </span>
          )}
        </div>
      </div>
    );
  };

const renderGeneralTab = () => (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:px-6 border-b border-gray-200 dark:border-gray-600">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
            Conversation Information
          </h3>
        </div>
        <div className="px-4 py-5 sm:p-6 space-y-4">
          {renderField('ID', 'id')}
          {renderField('Name', 'name')}
          {renderField('Product', 'conversationProduct')}
          {renderField('Mode', 'evaluationMode')}
          {renderField('Virtual Assistant Name', 'virtualAssistant')}
          {renderField('Start Time', 'startedOnUtc')}
          {renderField('Note', 'note')}
          {renderField('State', 'state')}
          {renderField('Enabled', 'isEnabled', 'checkbox')}
        </div>
      </div>
      <div className="flex justify-between">
        {isEditing ? (
          <>
            <button
              type="submit"
              className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                isDarkMode
                  ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                  : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
              }`}
            >
              Save Changes
            </button>
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                isDarkMode
                  ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
                  : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
              }`}
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            type="button"
            onClick={() => setIsEditing(true)}
            className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500 text-white'
                : 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-400 text-white'
            }`}
          >
            Edit Conversation
          </button>
        )}
      </div>
    </form>
  );

  const renderMessageOrderingTab = () => (
    <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
          Message Ordering
        </h3>
        <p className="text-sm text-gray-600 dark:text-gray-300">
          Message ordering information will be displayed here.
        </p>
      </div>
    </div>
  );

  const renderCustomersTab = () => (
    <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
          Customers
        </h3>
        <p className="text-sm text-gray-600 dark:text-gray-300">
          Customer information will be displayed here.
        </p>
      </div>
    </div>
  );

  const renderChangeLogsTab = () => (
    <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
          Change Logs
        </h3>
        <p className="text-sm text-gray-600 dark:text-gray-300">
          Change logs will be displayed here.
        </p>
      </div>
    </div>
  );

  if (error) return <ErrorPage isDarkMode={isDarkMode} errorMessage={error.message} errorCode={error.code} />
  if (loading) return <LoadingSpinner />

  return (
    <div className={`flex flex-col ${isDarkMode ? 'dark bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className="container mx-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Conversation Details</h1>
          <button
            type="button"
            onClick={handleBackClick}
            className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
                : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
            }`}
          >
            Back
          </button>
        </div>

        <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px">
            <li className="mr-2">
              <button
                className={tabClass('general')}
                onClick={() => setActiveTab('general')}
              >
                General
              </button>
            </li>
            <li className="mr-2">
              <button
                className={tabClass('messageOrdering')}
                onClick={() => setActiveTab('messageOrdering')}
              >
                Message Ordering
              </button>
            </li>
            <li className="mr-2">
              <button
                className={tabClass('customers')}
                onClick={() => setActiveTab('customers')}
              >
                Customers
              </button>
            </li>
            <li className="mr-2">
              <button
                className={tabClass('changeLogs')}
                onClick={() => setActiveTab('changeLogs')}
              >
                Change Logs
              </button>
            </li>
          </ul>
        </div>

        {activeTab === 'general' ? renderGeneralTab()
         : activeTab === 'messageOrdering' ? renderMessageOrderingTab()
         : activeTab === 'customers' ? renderCustomersTab()
         : renderChangeLogsTab()}
      </div>
    </div>
  );
};

export default ConversationDetailPage;
