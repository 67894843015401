import { tokenStorage } from './tokenSetter';

export const grabToken = async () => {
  try {
    let accessToken = await tokenStorage.getToken();

    if (!accessToken) {
      accessToken = await tokenStorage.waitForToken();
    }

    if (accessToken) {
      return accessToken;
    } else {
      throw new Error('Token not found');
    }
  } catch (error) {
    console.error('Error fetching token:', error.response ? error.response.data : error.message);
    throw error;
  }
};
