import { apiClient } from '../AxiosConfig.jsx';
import {
  Systems,
  SystemsCredentials,
  Companies
} from '../../../constants/paths.jsx';

class SystemService {
  /**
   * Get systems with pagination
   * @param {number} pageSize - Number of items per page
   * @returns {Promise<Array>} List of systems
   */
  async getSystems(pageSize = 200) {
    const response = await apiClient.get(Systems, {
      params: { pageSize }
    });
    return response.data.result;
  }

  /**
   * Get systems for a specific company
   * @param {string} clientId - Company/Client identifier
   * @returns {Promise<Array>} List of systems for the company
   */
  async getSystemsForCompany(clientId) {
    const response = await apiClient.get(`${Systems}/getForCompany/${clientId}`);
    return response.data.result;
  }

  /**
   * Get contract systems for a specific system
   * @param {string} systemId - System identifier
   * @returns {Promise<Array>} List of contract systems
   */
  async getContractSystems(systemId) {
    const response = await apiClient.get(`${Systems}/${systemId}/contractSystems`, {
      params: { pageSize: 1000 }
    });
    return response.data.result;
  }

  /**
   * Toggle contract system status
   * @param {string} systemId - System identifier
   * @param {string} contractId - Contract identifier
   * @param {string} contractSystemId - Contract system identifier
   * @returns {Promise<Object>} Updated contract system data
   */
  async toggleContractSystem(systemId, contractId, contractSystemId) {
    try {
      const response = await apiClient.put(
        `${Systems}/${systemId}/contractSystems/toggle`,
        { contractId, systemId, id: contractSystemId }
      );
      return response.data.result;
    } catch (error) {
      console.error('Toggle contract system error:', error);
      throw error;
    }
  }

  /**
   * Get system credentials by ID
   * @param {string} systemId - System identifier
   * @returns {Promise<Object>} System credentials
   */
  async getSystemWithCredentials(systemId) {
    const response = await apiClient.get(`${SystemsCredentials}/${systemId}`);
    return response.data.result;
  }

  /**
   * Get detailed system information including parsed properties and company name
   * @param {string} systemId - System identifier
   * @returns {Promise<Object>} Enhanced system details with JSON data
   */
  async getSystemDetails(systemId) {
    try {
      const response = await apiClient.get(`${Systems}/getWithCredentials/${systemId}`);
      const systemData = response.data.result;
      const processedData = await this.processSystemResponse(systemData);

      if (systemData.companyId) {
        try {
          const companyResponse = await this.getCompanyDetails(systemData.companyId);
          processedData.systemData.companyName = companyResponse.name;
        } catch (companyError) {
          console.error('Error fetching company details:', companyError);
          processedData.systemData.companyName = 'Unable to fetch company name';
        }
      }

      return processedData;
    } catch (error) {
      console.error('Error fetching system details:', error);
      throw error;
    }
  }

  /**
   * Process system response and handle JSON properties
   * @param {Object} systemData - Raw system data
   * @returns {Object} Processed system data with JSON information
   * @private
   */
  async processSystemResponse(systemData) {
    let jsonError = null;
    let formattedProperties = '{}';
    let isJsonValid = false;

    if (systemData.properties) {
      try {
        const properties = systemData.properties.replace(/'/g, '"');
        const parsedJson = JSON.parse(properties);
        formattedProperties = JSON.stringify(parsedJson, null, 2);
        isJsonValid = true;
      } catch (error) {
        console.error('Error parsing system properties:', error);
        jsonError = 'Invalid JSON format in properties';
        isJsonValid = false;
      }
    }

    return {
      systemData: {
        ...systemData,
        parsedProperties: formattedProperties
      },
      jsonData: {
        formatted: formattedProperties,
        error: jsonError,
        isValid: isJsonValid
      }
    };
  }

  /**
   * Update system details
   * @param {string} systemId - System identifier
   * @param {Object} systemData - System update data
   * @returns {Promise<Object>} Updated system data
   */
  async updateSystem(systemId, systemData) {
    try {
      const payload = {
        id: parseInt(systemData.id, 10),
        name: systemData.name || '',
        type: systemData.type || 0,
        companyId: systemData.companyId || '',
        guid: systemData.guid || '',
        description: systemData.description || '',
        ipAddress: systemData.ipAddress || '',
        batchNotify: systemData.batchNotify || '',
        foreignKey: systemData.foreignKey || '',
        properties: systemData.properties || '{}',
        databaseUserName: systemData.databaseUserName || '',
        databasePassword: systemData.databasePassword,
        processMotcheck: systemData.processMotcheck || false,
        state: systemData.state || 0,
      };

      const response = await apiClient.put(`${Systems}/${systemId}`, payload);
      return response.data;
    } catch (error) {
      console.error('Error updating system:', error);
      throw error;
    }
  }

  /**
   * Get company details by ID
   * @param {string} companyId - Company identifier
   * @returns {Promise<Object>} Company details
   */
  async getCompanyDetails(companyId) {
    const response = await apiClient.get(`${Companies}/${companyId}`);
    return response.data.result;
  }

  /**
   * Validate system fields
   * @param {Object} system - System data to validate
   * @returns {Object} Validation errors
   */
  validateFields(system) {
    const errors = {};
    const requiredFields = {
      name: 'Name',
      ipAddress: 'IP Address',
      databaseUserName: 'Database Username',
      databasePassword: 'Database Password'
    };

    Object.entries(requiredFields).forEach(([field, label]) => {
      if (!system[field] || system[field].trim() === '') {
        errors[field] = `${label} is required`;
      }
    });

    return errors;
  }

  /**
   * Create empty system state
   * @returns {Object} Initial system state
   */
  getInitialState() {
    return {
      databasePassword: '',
      id: '',
      companyId: '',
      name: '',
      description: '',
      guid: '',
      batchNotify: '',
      processMotcheck: false,
      foreignKey: '',
      type: '',
      ipAddress: '',
      databaseUserName: '',
      properties: '',
      state: '',
      parsedProperties: '{}',
      companyName: ''
    };
  }
}

export default new SystemService();
