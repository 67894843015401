import React from 'react';
import PropTypes from 'prop-types';

const ToggleSwitch = ({ isOpen, onToggle, disabled = false }) => {
  return (
    <button
      onClick={onToggle}
      disabled={disabled}
      className={`
        relative w-16 h-8 rounded-full transition-colors duration-200
        ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
        ${isOpen ? 'bg-green-500' : 'bg-gray-200'}
      `}
    >
      <span className={`
        absolute left-2 text-xs font-medium text-white
        ${isOpen ? 'opacity-100' : 'opacity-0'}
      `}>
        ON
      </span>

      <span className={`
        absolute right-2 text-xs font-medium text-gray-500
        ${!isOpen ? 'opacity-100' : 'opacity-0'}
      `}>
        OFF
      </span>

      <div className={`
        absolute top-1 w-6 h-6 bg-white rounded-full shadow-md
        transition-transform duration-200
        ${isOpen ? 'translate-x-9' : 'translate-x-1'}
        ${disabled && 'bg-gray-100'}
      `}>
        {disabled && (
          <div className="animate-spin h-4 w-4 border-2 border-gray-300 rounded-full border-t-transparent m-1" />
        )}
      </div>
    </button>
  );
};

ToggleSwitch.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default ToggleSwitch;
