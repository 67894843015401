import React from 'react';
import { useNavigate } from 'react-router-dom';
import companyService from '../../../services/api/calls/CompanyService';
import BaseTablePage from '../../../components/basic/BaseTablePage';

const DataPage = () => {
  const navigate = useNavigate();

  const columns = [
    { header: 'Name', accessor: 'name' },
    { header: 'ID', accessor: 'id' },
    { header: 'Address', accessor: 'address1' },
    { header: 'Town', accessor: 'town' },
    { header: 'Tel', accessor: 'tel' },
    {
      header: 'State',
      accessor: 'state',
      render: (row) => (
        <span
          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
            row.state === 0
              ? 'bg-green-100 text-green-800'
              : 'bg-red-100 text-red-800'
          }`}
        >
          {row.state === 0 ? 'Active' : 'Inactive'}
        </span>
      ),
    },
  ];

  const defaultFilters = {
    id: '',
    name: '',
    address1: '',
    town: '',
    tel: '',
    state: '',
  };

  const filterData = (data, filters) => {
    return data.filter(
      (item) =>
        (item.id?.toString().toLowerCase().includes(filters.id.toLowerCase()) ?? false) &&
        (item.name?.toLowerCase().includes(filters.name.toLowerCase()) ?? false) &&
        (item.address1?.toLowerCase().includes(filters.address1.toLowerCase()) ?? false) &&
        (item.town?.toLowerCase().includes(filters.town.toLowerCase()) ?? false) &&
        (item.tel?.toLowerCase().includes(filters.tel.toLowerCase()) ?? false) &&
        (item.state?.toString().includes(filters.state) ?? false)
    );
  };

  const fetchData = () => companyService.getCompanies(1000);

  const handleRowClick = (row) => {
    navigate(`/client-admin/clients/${row.id}`);
  };

  return (
    <BaseTablePage
      title="Client Admin | Clients"
      columns={columns}
      fetchData={fetchData}
      onRowClick={handleRowClick}
      defaultFilters={defaultFilters}
      filterData={filterData}
    />
  );
};

export default DataPage;
