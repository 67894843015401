import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useTheme } from '../../../contexts/ThemeContext';
import { grabToken } from '../../../utils/api';
import LoadingSpinner from '../../../components/misc/LoadingSpinner';
import VirtualAssistantDetailsForm from './components/VirtualAssistantDetailForm.jsx';
import {
  TabNavigation,
  NavigationHeader,
  useFormHandlers,
  handleBackNavigation,
  getCommonStyles,
  handleApiError
} from '../../../components/helpers/CommonHelper';
import {
  FormOutlined,
  FileTextOutlined,
  CalendarOutlined,
  HistoryOutlined
} from '@ant-design/icons';

const VirtualAssistantDetailPage = () => {
  const [assistant, setAssistant] = useState({
    id: '',
    name: '',
    phoneNumber: '',
    timeZone: '',
    note: '',
    isEmailEnabled: false,
    isSmsEnabled: false,
    companyId: '',
    emailProviderId: '',
    state: 0,
    emailSettings: {
      email: '',
      signature: '',
      outOfOffice: '',
      outOfOfficeEnabled: false,
      emailsIntervalInSeconds: 0,
      dailyEmailsLimit: 0,
    },
    company: { id: '', name: '' },
    emailProvider: { id: '', name: '' },
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('details');

  const { id } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const { containerClass } = getCommonStyles(isDarkMode);

  const {
    isEditing,
    handleEditClick,
    handleCancelEdit,
    handleFieldChange
  } = useFormHandlers({
    fetchDetails: () => fetchAssistantDetails(id),
    onSave: (name, value) => {
      setAssistant(prev => ({
        ...prev,
        [name]: value
      }));
    },
    initialData: assistant
  });

  const tabs = [
    { id: 'details', label: 'Details', icon: <FormOutlined /> },
    { id: 'schedule', label: 'Working Schedule', icon: <CalendarOutlined /> },
    { id: 'events', label: 'Events', icon: <HistoryOutlined /> },
    { id: 'logs', label: 'Logs', icon: <FileTextOutlined /> }
  ];

  useEffect(() => {
    if (id) {
      fetchAssistantDetails(id);
    }
  }, [id]);

  const fetchAssistantDetails = async (assistantId) => {
    setLoading(true);
    setError(null);
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = await grabToken();
      const response = await axios.get(`${apiBaseUrl}/VirtualAssistants/${assistantId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
      setAssistant(response.data.result);
      console.log("reponse:", response);
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const token = await grabToken();
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

      const assistantData = {
        id: assistant.id,
        companyId: assistant.company.id,
        emailProviderId: assistant.emailProviderId,
        name: values.name,
        phoneNumber: values.phoneNumber,
        note: values.note || '',
        isEmailEnabled: values.isEmailEnabled || false,
        isSmsEnabled: values.isSmsEnabled || false,
        emailSettings: {
          email: values.emailSettings?.email || '',
          signature: values.emailSettings?.signature || '',
          outOfOffice: values.emailSettings?.outOfOffice || '',
          outOfOfficeEnabled: values.emailSettings?.outOfOfficeEnabled || false,
          emailsIntervalInSeconds: parseInt(values.emailSettings?.emailsIntervalInSeconds) || 0,
          dailyEmailsLimit: parseInt(values.emailSettings?.dailyEmailsLimit) || 0,
        }
      };

      const response = await axios.put(
        `${apiBaseUrl}/VirtualAssistants/${assistant.id}`,
        assistantData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        handleCancelEdit();
        await fetchAssistantDetails(assistant.id);
      }
    } catch (error) {
      console.error('Update error:', error);
      if (error.response) {
        const errorMessage = error.response.data.detail || error.response.data.title || 'Failed to update virtual assistant';
        setError(errorMessage);
      } else if (error.request) {
        setError('Network error occurred. Please try again.');
      } else {
        setError(error.message || 'An unexpected error occurred');
      }
    }
  };

  const handleBack = () => {
    handleBackNavigation(navigate, location, '/client-admin/virtual-assistants');
  };

  const renderDetailsTab = () => (
    <VirtualAssistantDetailsForm
      assistant={assistant}
      isEditing={isEditing}
      onSubmit={handleSubmit}
      onCancel={handleCancelEdit}
      onEdit={handleEditClick}
      isDarkMode={isDarkMode}
      onFieldChange={(name, value) => handleFieldChange('main', name, value)}
    />
  );

  const renderScheduleTab = () => (
    <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
          Working Schedule
        </h3>
        <p className="text-sm text-gray-600 dark:text-gray-300">
          Working schedule configuration will be displayed here.
        </p>
      </div>
    </div>
  );

  const renderEventsTab = () => (
    <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
          Events
        </h3>
        <p className="text-sm text-gray-600 dark:text-gray-300">
          Recent events and activities will be displayed here.
        </p>
      </div>
    </div>
  );

  const renderLogsTab = () => (
    <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
          Virtual Assistant Logs
        </h3>
        {assistant.lastEvent ? (
          <div>
            <p className="text-gray-800 dark:text-gray-200"><strong>Last Event:</strong> {assistant.lastEvent.data.message}</p>
            <p className="text-gray-800 dark:text-gray-200"><strong>Additional Message:</strong> {assistant.lastEvent.data.additionalMessage}</p>
            <p className="text-gray-800 dark:text-gray-200"><strong>Created Date:</strong> {new Date(assistant.lastEvent.createdDate).toLocaleString()}</p>
          </div>
        ) : (
          <p className="text-sm text-gray-600 dark:text-gray-300">No logs available</p>
        )}
      </div>
    </div>
  );

  if (error) {
    return (
      <div className={`p-6 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-2xl font-bold mb-4">Error</h2>
          <p className="text-lg mb-4">{error}</p>
          <button
            onClick={() => {
              setError(null);
              fetchAssistantDetails(id);
            }}
            className="component-button component-button-primary"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }
  if (loading) return <LoadingSpinner />;

  return (
    <div className={containerClass}>
      <div className="container mx-auto p-6">
        <NavigationHeader
          title={`Virtual Assistant Details | ${assistant.name}`}
          onBackClick={handleBack}
          isDarkMode={isDarkMode}
        />
        <TabNavigation
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={setActiveTab}
          isDarkMode={isDarkMode}
        />
        {activeTab === 'details' ? renderDetailsTab() :
         activeTab === 'schedule' ? renderScheduleTab() :
         activeTab === 'events' ? renderEventsTab() :
         renderLogsTab()}
      </div>
    </div>
  );
};

export default VirtualAssistantDetailPage;
