import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table, notification } from 'antd';
import { grabToken } from '../../../../utils/api';
import { fetchContractsList, fetchContractSystemResponse, toggleContractSystem } from '../../../../services/api/ApiFetch.jsx';
import CopyButton from '../../../../components/buttons/CopyButton.jsx';
import ToggleSwitch from '../../../../components/buttons/toggleSwitch.jsx';
import "../../../../styles/common.css";
import 'antd/dist/reset.css';

const SystemContracts = ({ systemId, companyId, isDarkMode }) => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const numericSystemId = typeof systemId === 'string' ? parseInt(systemId, 10) : systemId;
  const numericCompanyId = typeof companyId === 'string' ? parseInt(companyId, 10) : companyId;

  const handleToggle = async (contractId, contractSystem) => {
    try {
      setContracts(prevContracts =>
        prevContracts.map(contract =>
          contract.contractId === contractId
            ? { ...contract, isLoading: true }
            : contract
        )
      );

      const token = await grabToken();
      const result = await toggleContractSystem(numericSystemId, contractId, contractSystem?.id, token);

      setContracts(prevContracts => {
        const temp = [...prevContracts];
        const indexToUpdate = temp.findIndex(contract => contract.contractId === contractId);
        if (indexToUpdate !== -1) {
          temp[indexToUpdate] = {
            ...temp[indexToUpdate],
            contractSystem: result,
            isLoading: false
          };
        }
        return temp;
      });

      notification.success({
        message: 'Success',
        description: result.state === 2
          ? 'Contract system has been deactivated'
          : 'Contract system has been activated'
      });

    } catch (error) {
      console.error('Error toggling contract system:', error);
      setContracts(prevContracts =>
        prevContracts.map(contract =>
          contract.contractId === contractId
            ? { ...contract, isLoading: false }
            : contract
        )
      );

      if (error.response?.data?.errors?.length > 0) {
        notification.error({
          message: 'Updating of Systems failed',
          description: error.response.data.errors[0],
        });
      }
    }
  };

  const getContracts = useCallback(async () => {
    setLoading(true);
    try {
      const token = await grabToken();
      const contractsList = await fetchContractsList(numericCompanyId, token);
      const contractSystems = await fetchContractSystemResponse(token, numericSystemId);

      const mappedData = contractSystems.map(system => {
        const matchingContract = contractsList.find(contract => contract.id === system.contractId);
        return {
          contractId: system.id,
          shortId: system.shortId,
          productName: matchingContract?.product?.name || 'N/A',
          contractSystem: system
        };
      });

      setContracts(mappedData);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message || 'Failed to fetch data');
      setContracts([]);
    } finally {
      setLoading(false);
    }
  }, [numericSystemId, numericCompanyId]);

  useEffect(() => {
    if (numericSystemId && numericCompanyId) {
      getContracts();
    }
  }, [numericSystemId, numericCompanyId, getContracts]);

  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Contract ID',
      dataIndex: 'contractId',
      key: 'contractId',
      render: (text) => (
        <div className="flex items-center space-x-2">
          <span>{text}</span>
          <CopyButton textToCopy={text} className="component-button component-button-secondary" />
        </div>
      ),
    },
    {
      title: 'Short ID',
      dataIndex: 'shortId',
      key: 'shortId',
      render: (text) => (
        <div className="flex items-center space-x-2">
          <span>{text}</span>
          <CopyButton textToCopy={text} className="component-button component-button-secondary" />
        </div>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <ToggleSwitch
          isOpen={record.contractSystem?.state === 0}
          onToggle={() => handleToggle(record.contractId, record.contractSystem)}
          disabled={record.isLoading}
          className="component-button component-button-primary"
        />
      ),
    },
  ];

  if (error) {
    return (
      <div className={`component-container ${isDarkMode ? 'dark-mode' : ''}`}>
        <div className="component-error">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`relative ${isDarkMode ? 'dark-mode' : ''}`}
      style={{ backgroundColor: 'transparent' }}
    >
      <div className="component-content">
        <Table
          columns={columns}
          dataSource={contracts}
          rowKey="contractId"
          loading={loading}
          pagination={false}
          className={`component-table ${isDarkMode ? 'dark-mode' : ''}`}
          size="middle"
          style={{ backgroundColor: 'transparent' }}
        />
      </div>
    </div>
  );
};

SystemContracts.propTypes = {
  systemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isDarkMode: PropTypes.bool.isRequired
};

export default SystemContracts;
