import React from 'react';
import { Form, Input, Switch } from 'antd';
import PropTypes from 'prop-types';
import UserInfo from '../../../../components/basic/UserInfo';
import BaseDetailsForm from '../../../../components/basic/BaseDetailsForm';

const EmailProviderDetailsForm = ({
  provider,
  isEditing,
  onSubmit,
  onCancel,
  onEdit,
  isDarkMode
}) => {
  const initialValues = {
    ...provider,
    'sender.server': provider.sender.server,
    'sender.port': provider.sender.port,
    'sender.sslEnabled': provider.sender.sslEnabled,
    'recipient.server': provider.recipient.server,
    'recipient.port': provider.recipient.port,
    'recipient.sslEnabled': provider.recipient.sslEnabled,
  };

  const formContent = (
    <>
      <Form.Item
        label={<span className="component-label">Name</span>}
        name="name"
        rules={[{ required: true, message: 'Name is required' }]}
        className="component-form-item"
      >
        <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
      </Form.Item>

      <div className="component-section mt-6">
        <h4 className="text-lg font-bold dark:text-white mb-4">Sender Configuration</h4>
        <Form.Item
          label={<span className="component-label">Server</span>}
          name={['sender', 'server']}
          rules={[{ required: true, message: 'Server is required' }]}
          className="component-form-item"
        >
          <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
        </Form.Item>

        <Form.Item
          label={<span className="component-label">Port</span>}
          name={['sender', 'port']}
          rules={[{ required: true, message: 'Port is required' }]}
          className="component-form-item"
        >
          <Input type="number" className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
        </Form.Item>

        <Form.Item
          label={<span className="component-label">SSL Enabled</span>}
          name={['sender', 'sslEnabled']}
          valuePropName="checked"
          className="component-form-item"
        >
          <Switch />
        </Form.Item>
      </div>

      <div className="component-section mt-6">
        <h4 className="text-lg font-bold dark:text-white mb-4">Recipient Configuration</h4>
        <Form.Item
          label={<span className="component-label">Server</span>}
          name={['recipient', 'server']}
          rules={[{ required: true, message: 'Server is required' }]}
          className="component-form-item"
        >
          <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
        </Form.Item>

        <Form.Item
          label={<span className="component-label">Port</span>}
          name={['recipient', 'port']}
          rules={[{ required: true, message: 'Port is required' }]}
          className="component-form-item"
        >
          <Input type="number" className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
        </Form.Item>

        <Form.Item
          label={<span className="component-label">SSL Enabled</span>}
          name={['recipient', 'sslEnabled']}
          valuePropName="checked"
          className="component-form-item"
        >
          <Switch />
        </Form.Item>
      </div>

      <div className="border-t border-gray-200 dark:border-gray-600 mt-6 pt-4">
        <div className="grid grid-cols-2 gap-6">
          <UserInfo
            label="Created By"
            user={provider.createdByUser}
            date={provider.createdDate}
          />
          <UserInfo
            label="Updated By"
            user={provider.updatedByUser}
            date={provider.updatedDate}
          />
        </div>
      </div>
    </>
  );

  return (
    <BaseDetailsForm
      title="Provider Details"
      isEditing={isEditing}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onEdit={onEdit}
      isDarkMode={isDarkMode}
      initialValues={initialValues}
    >
      {formContent}
    </BaseDetailsForm>
  );
};

EmailProviderDetailsForm.propTypes = {
  provider: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    enforceGraphApi: PropTypes.bool,
    createdDate: PropTypes.string,
    updatedDate: PropTypes.string,
    createdByUser: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    updatedByUser: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    sender: PropTypes.shape({
      server: PropTypes.string,
      port: PropTypes.number,
      sslEnabled: PropTypes.bool,
    }),
    recipient: PropTypes.shape({
      server: PropTypes.string,
      port: PropTypes.number,
      sslEnabled: PropTypes.bool,
    }),
    state: PropTypes.number,
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
};

export default EmailProviderDetailsForm;
