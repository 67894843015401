import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import systemsService from '../../../services/api/calls/SystemService';
import { handleApiError } from '../../../services/api/errorHandler';
import ErrorPage from '../../error/ErrorPage';
import LoadingSpinner from '../../../components/misc/LoadingSpinner';
import { useTheme } from '../../../contexts/ThemeContext';
import PageContainer from '../../../components/basic/PageContainer';
import PageHeader from '../../../components/basic/PageHeader';
import FilterableTable from '../../../components/basic/FilterableTable';
import PaginatedFooter from '../../../components/basic/PaginatedFooter';
import { getPageNumbers } from "../../../components/helpers/CommonHelper.jsx";
import { useTableFilters } from '../../../hooks/useTableFilters';

const SystemDataPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  const filterLogic = (item, filters) => {
    return (
      item.id.toString().toLowerCase().includes(filters.id.toLowerCase()) &&
      (item.company?.name?.toLowerCase().includes(filters.clientName.toLowerCase()) ?? false) &&
      (item.name?.toLowerCase().includes(filters.systemName.toLowerCase()) ?? false) &&
      (item.foreignKey?.toLowerCase().includes(filters.foreignKey.toLowerCase()) ?? false) &&
      (filters.motCheck === '' ||
        item.processMotcheck?.toString().toLowerCase() === filters.motCheck.toLowerCase())
    );
  };

  const {
    filters,
    handleFilterChange,
    filterData
  } = useTableFilters({
    id: '',
    clientName: '',
    systemName: '',
    foreignKey: '',
    motCheck: '',
  }, filterLogic);

  useEffect(() => {
    fetchSystems();
  }, []);

  const fetchSystems = async () => {
    try {
      const result = await systemsService.getSystems(200);
      if (Array.isArray(result?.list)) {
        setData(result.list);
      } else {
        setError('Received data is not in the expected format');
      }
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorPage isDarkMode={isDarkMode} errorMessage={error} />;

  const filteredData = filterData(data);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (action) => {
    if (action === 'prev') {
      setCurrentPage((p) => Math.max(p - 1, 1));
    } else if (action === 'next') {
      setCurrentPage((p) => Math.min(p + 1, totalPages));
    } else {
      setCurrentPage(action);
    }
  };

  const handleFilterWithReset = (accessor, value) => {
    handleFilterChange(accessor, value);
    setCurrentPage(1);
  };

  const handleRowClick = (row) => {
    navigate(`/client-admin/systems/${row.id}`);
  };

  const handleItemsPerPageChange = (val) => {
    setItemsPerPage(val);
    setCurrentPage(1);
  };

  const columns = [
    {
      header: 'ID',
      accessor: 'id',
    },
    {
      header: 'Client',
      accessor: 'clientName',
      render: (row) => row.company?.name || '',
    },
    {
      header: 'System Name',
      accessor: 'systemName',
      render: (row) => row.name || '',
    },
    {
      header: 'Foreign Key',
      accessor: 'foreignKey',
    },
  ];

  return (
    <PageContainer>
      <PageHeader title="Client Admin | Systems">
        <Link
          to="/client-admin/systems/add"
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600
                     transition-colors duration-200"
        >
          Add New System
        </Link>
      </PageHeader>

      <div className="flex-grow overflow-auto">
        <FilterableTable
          columns={columns}
          data={currentItems}
          filters={filters}
          onFilterChange={handleFilterWithReset}
          onRowClick={handleRowClick}
          isDarkMode={isDarkMode}
          noDataText="No data available"
        />
      </div>

      <PaginatedFooter
        currentPage={currentPage}
        totalPages={totalPages}
        indexOfFirstItem={indexOfFirstItem}
        indexOfLastItem={indexOfLastItem}
        filteredDataLength={filteredData.length}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
        onPageChange={paginate}
        getPageNumbers={getPageNumbers}
      />
    </PageContainer>
  );
};

export default SystemDataPage;
