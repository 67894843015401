import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { grabToken } from '../../../utils/api';
import { useTheme } from '../../../contexts/ThemeContext';
import { v4 as uuidv4 } from 'uuid';
import { Form, Input, Card, Select, notification } from 'antd';
import ErrorPage from '../../error/ErrorPage';
import LoadingSpinner from '../../../components/misc/LoadingSpinner';
import { NavigationHeader } from '../../../components/helpers/CommonHelper';
import { X, Plus } from 'lucide-react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const validateField = (name, value) => {
  switch (name) {
    case 'company.id':
      return value ? '' : 'Company is required';
    case 'name':
      return value && value.trim() !== '' ? '' : 'Name is required';
    case 'address1':
      return value && value.trim() !== '' ? '' : 'Address is required';
    case 'town':
      return value && value.trim() !== '' ? '' : 'Town is required';
    case 'county':
      return value && value.trim() !== '' ? '' : 'County is required';
    case 'zipPostCode':
      return value && value.trim() !== '' ? '' : 'Zip/Post Code is required';
    case 'tel':
      return value && value.replace(/\D/g, '').length >= 6 ? '' : 'Valid telephone number is required';
    case 'country':
      return value && value.trim() !== '' ? '' : 'Country is required';
    default:
      return '';
  }
};

const AddBranchPage = () => {
  const [form] = Form.useForm();
  const [branch, setBranch] = useState({
    company: {
      id: '',
      name: ''
    },
    distributionEmails: [],
    reference: '',
    codesArrayJson: '[]',
    address1: '',
    address2: '',
    county: '',
    country: '',
    tel: '',
    town: '',
    zipPostCode: '',
    accountcode: '',
    codes: [],
    state: 0,
    id: '',
    name: ''
  });

  const [setErrors] = useState({});
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newCode, setNewCode] = useState('');

  useEffect(() => {
    fetchClientList();
  }, []);

  const fetchClientList = async () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    try {
      const token = await grabToken();
      const response = await axios.get(`${apiBaseUrl}/Companies/filter`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        params: {
          pageSize: 100
        }
      });

      if (Array.isArray(response.data?.result?.list)) {
        setClients(response.data.result.list);
      } else {
        setError('Received data is not in the expected format');
      }
    } catch (error) {
      setError(error.response ? `Error: ${error.response.status} - ${error.response.data}` : error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCompanyChange = (value) => {
    const selectedCompany = clients.find(client => client.id === value);
    setBranch(prev => ({
      ...prev,
      company: {
        id: selectedCompany.id,
        name: selectedCompany.name
      }
    }));
    form.setFieldsValue({
      'company.id': selectedCompany.id
    });
  };

  const handleGenerateGuid = () => {
    const newGuid = uuidv4();
    form.setFieldsValue({ id: newGuid });
    setBranch(prev => ({
      ...prev,
      id: newGuid
    }));
  };

  const handleGenerateAccountcode = () => {
    const newCode = uuidv4();
    form.setFieldsValue({ accountcode: newCode });
    setBranch(prev => ({
      ...prev,
      accountcode: newCode
    }));
  };

  const handleDeleteCode = (indexToDelete) => {
    const newCodes = branch.codes.filter((_, index) => index !== indexToDelete);
    setBranch(prev => ({
      ...prev,
      codes: newCodes
    }));
  };

  const handleAddCode = () => {
    if (newCode.trim() !== '') {
      setBranch(prev => ({
        ...prev,
        codes: [...prev.codes, newCode.trim()]
      }));
      setNewCode('');
    }
  };

  const handleSubmit = async (values) => {
    try {
      const formErrors = Object.keys(values).reduce((acc, key) => {
        const fieldError = validateField(key, values[key]);
        if (fieldError) {
          acc[key] = fieldError;
        }
        return acc;
      }, {});

      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        return;
      }

      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = await grabToken();

      const requestBody = {
        company: branch.company,
        distributionEmails: branch.distributionEmails,
        reference: values.reference,
        codesArrayJson: JSON.stringify(branch.codes),
        address1: values.address1,
        address2: values.address2,
        county: values.county,
        country: values.country.toLowerCase(),
        tel: values.tel,
        town: values.town,
        zipPostCode: values.zipPostCode,
        accountcode: values.accountcode,
        state: values.state || '0',
        name: values.name
      };

      const response = await axios.post(
        `${apiBaseUrl}/Branches`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        notification.success({
          message: 'Branch Created',
          description: 'The branch has been successfully created.',
        });
        navigate('/client-admin/branches');
      }
    } catch (error) {
      console.error('Error adding branch:', error);
      notification.error({
        message: 'Error Creating Branch',
        description: error.response
          ? `Error: ${error.response.status} - ${JSON.stringify(error.response.data)}`
          : error.message,
      });
    }
  };

  const handleBack = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else {
      navigate('/client-admin/branches');
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorPage isDarkMode={isDarkMode} errorMessage={error} />;

  return (
    <div className={`component-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="container mx-auto p-6 dark:text-white">
        <NavigationHeader
          title="Add New Branch"
          onBackClick={handleBack}
          isDarkMode={isDarkMode}
        />

        <Card
          className={`component-card ${isDarkMode ? 'dark-mode' : ''}`}
          title={
            <div className="component-header">
              <span className="component-title mt-3">Branch Information</span>
            </div>
          }
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={branch}
            className="component-form"
          >
            <Form.Item
              label={<span className="component-label">Company</span>}
              name={['company', 'name']}
              rules={[{ required: true, message: 'Company is required' }]}
              className="component-form-item"
            >
              <Select
                className={`component-select text-gray-800 dark:text-white ${isDarkMode ? 'dark-mode' : ''}`}
                placeholder="Select Company"
                onChange={handleCompanyChange}
                popupClassName={isDarkMode ? 'dark-mode-select-dropdown' : ''}
              >
                {clients.map((client) => (
                  <Select.Option key={client.id} value={client.id}>
                    {client.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Company ID</span>}
              name={['company', 'id']}
              className="component-form-item"
            >
              <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} readOnly />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Branch Name</span>}
              name="name"
              rules={[{ required: true, message: 'Branch name is required' }]}
              className="component-form-item"
            >
              <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Branch ID</span>}
              name="id"
              className="component-form-item"
            >
              <div className="flex space-x-2">
                <Input
                  className={`component-input ${isDarkMode ? 'dark-mode' : ''}`}
                  placeholder="Generate a GUID"
                  readOnly
                  value={branch.id}
                />
                <button
                  type="button"
                  onClick={handleGenerateGuid}
                  className="component-button component-button-primary whitespace-nowrap h-[32px]"
                >
                  Generate
                </button>
              </div>
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Account Code</span>}
              name="accountcode"
              className="component-form-item"
            >
              <div className="flex space-x-2">
                <Input
                  className={`component-input ${isDarkMode ? 'dark-mode' : ''}`}
                  placeholder="Generate an account code"
                  readOnly
                  value={branch.accountcode}
                />
                <button
                  type="button"
                  onClick={handleGenerateAccountcode}
                  className="component-button component-button-primary whitespace-nowrap h-[32px]"
                >
                  Generate
                </button>
              </div>
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Reference</span>}
              name="reference"
              className="component-form-item"
            >
              <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Address 1</span>}
              name="address1"
              rules={[{ required: true, message: 'Address 1 is required' }]}
              className="component-form-item"
            >
              <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Address 2</span>}
              name="address2"
              className="component-form-item"
            >
              <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Town</span>}
              name="town"
              rules={[{ required: true, message: 'Town is required' }]}
              className="component-form-item"
            >
              <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">County</span>}
              name="county"
              rules={[{ required: true, message: 'County is required' }]}
              className="component-form-item"
            >
              <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Country</span>}
              name="country"
              rules={[{ required: true, message: 'Country is required' }]}
              className="component-form-item"
            >
              <Select
                className={`component-select text-gray-800 dark:text-white ${isDarkMode ? 'dark-mode' : ''}`}
                placeholder="Select Country"
                popupClassName={isDarkMode ? 'dark-mode-select-dropdown' : ''}
              >
                <Select.Option value="United Kingdom">United Kingdom</Select.Option>
                <Select.Option value="United Arab Emirates">United Arab Emirates</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Zip/Post Code</span>}
              name="zipPostCode"
              rules={[{ required: true, message: 'Zip/Post Code is required' }]}
              className="component-form-item"
            >
              <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Telephone</span>}
              name="tel"
              rules={[{ required: true, message: 'Telephone is required' }]}
              className="component-form-item"
            >
              <PhoneInput
                country={'gb'}
                onlyCountries={['gb', 'ae']}
                value={branch.tel}
                onChange={(phone) => {
                  form.setFieldsValue({ tel: phone });
                  setBranch(prev => ({ ...prev, tel: phone }));
                }}
                inputClass={`component-input ${isDarkMode ? 'dark-mode' : ''}`}
                containerClass="w-full"
                buttonClass={isDarkMode ? 'dark-mode' : ''}
                dropdownClass={isDarkMode ? 'dark-mode-dropdown' : ''}
              />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">State</span>}
              name="state"
              className="component-form-item"
            >
              <Input type="number" className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Codes</span>}
              className="component-form-item"
            >
              <div className="flex flex-wrap gap-2 mb-2">
                {branch.codes.map((code, index) => (
                  <span
                    key={index}
                    className={`px-3 py-1 rounded-md text-base flex items-center ${
                      isDarkMode
                        ? 'bg-blue-600 text-white'
                        : 'bg-blue-100 text-blue-800'
                    }`}
                  >
                    {code}
                    <button
                      type="button"
                      onClick={() => handleDeleteCode(index)}
                      className="ml-2 focus:outline-none"
                    >
                      <X size={16} />
                    </button>
                  </span>
                ))}
              </div>
              <div className="flex space-x-2">
                <Input
                  value={newCode}
                  onChange={(e) => setNewCode(e.target.value)}
                  className={`component-input ${isDarkMode ? 'dark-mode' : ''}`}
                  placeholder="Add new code"
                  onPressEnter={(e) => {
                    e.preventDefault();
                    handleAddCode();
                  }}
                />
                <button
                  type="button"
                  onClick={handleAddCode}
                  className="component-button component-button-primary whitespace-nowrap h-[32px] flex items-center"
                >
                  <Plus size={20} />
                </button>
              </div>
            </Form.Item>

            <Form.Item className="component-form-item">
              <button
                type="submit"
                className="component-button component-button-primary"
              >
                Add Branch
              </button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default AddBranchPage;
