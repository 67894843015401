import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

export const tokenStorage = {
  memoryStorage: new Map(),
  listeners: new Set(),

  async checkIncognito() {
    try {
      await localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      return false;
    } catch (e) {
      return true;
    }
  },

  async setToken(token) {
    const isIncognito = await this.checkIncognito();
    if (isIncognito) {
      this.memoryStorage.set('app.Authorization', token);
      this.memoryStorage.set('app.lastTokenRefresh', Date.now().toString());
    } else {
      window.localStorage.setItem('app.Authorization', token);
      window.localStorage.setItem('app.lastTokenRefresh', Date.now().toString());
    }

    this.listeners.forEach(listener => listener(token));
  },

  async getToken() {
    const isIncognito = await this.checkIncognito();
    const token = isIncognito
      ? this.memoryStorage.get('app.Authorization')
      : window.localStorage.getItem('app.Authorization');

    return token || null;
  },

  async waitForToken(timeout = 5000) {
    const token = await this.getToken();
    if (token) return token;

    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        this.listeners.delete(listener);
        reject(new Error('Token wait timeout'));
      }, timeout);

      const listener = (token) => {
        clearTimeout(timeoutId);
        this.listeners.delete(listener);
        resolve(token);
      };

      this.listeners.add(listener);
    });
  },

  async clear() {
    const isIncognito = await this.checkIncognito();
    if (isIncognito) {
      this.memoryStorage.clear();
    } else {
      window.localStorage.clear();
    }
    this.listeners.clear();
  }
};

const TokenSetter = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const dispatch = useDispatch();
  const [isTokenSet, setIsTokenSet] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const getUserMetadata = async () => {
      const isIncognito = await tokenStorage.checkIncognito();
      // const currentToken = await tokenStorage.getToken();


      if (isAuthenticated && !isTokenSet) {
        const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
        try {
          const response = await getAccessTokenSilently({
            authorizationParams: {
              audience,
              scope: 'openid profile email offline_access read:current_user',
            },
            detailedResponse: true,
            cacheMode: isIncognito ? 'off' : 'on'
          });

          if (!isMounted) return;


          const accessToken = response.access_token || response;
          await tokenStorage.setToken(accessToken);

          dispatch({
            type: 'user/LOGIN',
            payload: { user },
          });

          setIsTokenSet(true);
          setError(null);
        } catch (e) {
          if (!isMounted) return;
          console.error('TokenSetter: Token acquisition error:', {
            error: e,
            errorName: e.name,
            errorMessage: e.message,
            errorType: e.error
          });
          setError(e);
        }
      }
      if (isMounted) {
        setIsLoading(false);
      }
    };

    getUserMetadata();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently, isAuthenticated, dispatch, isTokenSet, user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return children;
};

TokenSetter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TokenSetter;
