import axios from 'axios';
import { grabToken } from '../../utils/api';

const apiClient = axios.create();
const reportingApiClient = axios.create();


// axios.interceptors.response.use(
//   response => response,
//   async err => {
//     if (await handle403(err)) {
//       return undefined;
//     }
//     return Promise.reject(err);
//   }
// );
// Bohdans idea ^^





apiClient.interceptors.request.use(
  async (config) => {
    const token = await grabToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.Accept = 'application/json';
    }
    return config;
  },
  (error) => Promise.reject(error)
);

reportingApiClient.interceptors.request.use(
  async (config) => {
    config.headers['x-functions-key'] = process.env.REACT_APP_REPORTING_API_KEY;
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => Promise.reject(error)
);

export { apiClient, reportingApiClient };
