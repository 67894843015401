import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Input, Card } from 'antd';
import classNames from 'classnames';
import '../../styles/FilterableTable.css';

const FilterableTable = ({
  columns,
  data,
  filters,
  onFilterChange,
  onRowClick,
  isDarkMode,
  noDataText = 'No data available'
}) => {
  useEffect(() => {
  }, [isDarkMode]);

  const antColumns = columns.map(col => ({
    title: (
      <div className="space-y-2">
        <div>{col.header}</div>
        <Input
          className={classNames({ 'dark-input': isDarkMode })}
          placeholder={col.placeholder || `Search ${col.header}`}
          value={filters[col.accessor]}
          onChange={(e) => onFilterChange(col.accessor, e.target.value)}
        />
      </div>
    ),
    dataIndex: col.accessor,
    key: col.accessor,
    render: col.render ? (text, record) => col.render(record) : undefined
  }));

  return (
    <Card className={classNames('table-card', { 'dark-card': isDarkMode })}>
      <Table
        className={classNames('filterable-table', { 'dark-mode': isDarkMode })}
        columns={antColumns}
        dataSource={data}
        locale={{ emptyText: noDataText }}
        onRow={(record) => ({
          onClick: () => onRowClick?.(record),
          className: onRowClick ? 'clickable-row' : ''
        })}
        pagination={false}
      />
    </Card>
  );
};

FilterableTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      render: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.objectOf(PropTypes.string).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  isDarkMode: PropTypes.bool,
  noDataText: PropTypes.string,
};

export default FilterableTable;
