import React from 'react';
import { Form, Input, Tag, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CopyButton from '../../../../components/buttons/CopyButton.jsx';
import "../../../../styles/common.css";
import BaseDetailsForm from '../../../../components/basic/BaseDetailsForm';

const BranchDetailsForm = ({
  branch,
  isEditing,
  onSubmit,
  onCancel,
  onEdit,
  isDarkMode,
  onAddCode,
  onDeleteCode,
  newCode,
  setNewCode
}) => {
  const [companyName, setCompanyName] = React.useState('');

  React.useEffect(() => {
    if (branch) {
      setCompanyName(branch.company?.name || '');
    }
  }, [branch]);

  const handleAddCode = (e) => {
    e.preventDefault();
    if (newCode?.trim()) {
      onAddCode(e);
    }
  };

  const handlePhoneChange = (value, form) => {
    if (form) {
      form.setFieldsValue({ tel: value });
    }
  };

  const inputClass = `component-input ${isDarkMode ? 'dark-mode' : ''}`;

  const initialValues = {
    name: branch?.name || '',
    reference: branch?.reference || '',
    address1: branch?.address1 || '',
    address2: branch?.address2 || '',
    town: branch?.town || '',
    county: branch?.county || '',
    country: branch?.country || '',
    zipPostCode: branch?.zipPostCode || '',
    accountcode: branch?.accountcode || '',
    tel: branch?.tel || '',
    codes: branch?.codes || []
  };

  const formContent = (form) => (
    <>
      <Form.Item
        label={<span className="component-label">Company Name</span>}
        className="component-form-item"
      >
        <Input
          value={companyName}
          disabled={true}
          className={inputClass}
        />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">Branch Name</span>}
        name="name"
        rules={[{ required: true, message: 'Branch name is required' }]}
        className="component-form-item"
      >
        <Input className={inputClass} />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">Reference</span>}
        name="reference"
        className="component-form-item"
      >
        <Input className={inputClass} />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">Address Line 1</span>}
        name="address1"
        rules={[{ required: true, message: 'Address is required' }]}
        className="component-form-item"
      >
        <Input className={inputClass} />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">Town</span>}
        name="town"
        rules={[{ required: true, message: 'Town is required' }]}
        className="component-form-item"
      >
        <Input className={inputClass} />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">County</span>}
        name="county"
        rules={[{ required: true, message: 'County is required' }]}
        className="component-form-item"
      >
        <Input className={inputClass} />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">Country</span>}
        name="country"
        className="component-form-item"
      >
        <Select
          className="component-select"
          dropdownClassName={`${isDarkMode ? 'dark-mode' : ''}`}
        >
          <Select.Option value="uk">UK</Select.Option>
          <Select.Option value="ae">AE</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={<span className="component-label">Zip/Post Code</span>}
        name="zipPostCode"
        rules={[{ required: true, message: 'Zip/Post Code is required' }]}
        className="component-form-item"
      >
        <Input className={inputClass} />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">Account Code</span>}
        name="accountcode"
        className="component-form-item"
      >
        <div className="relative">
          <Input
            className={inputClass}
            value={branch?.accountcode || ''}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            <CopyButton textToCopy={branch?.accountcode || ''} />
          </div>
        </div>
      </Form.Item>

      <Form.Item
        label={<span className="component-label">Telephone</span>}
        name="tel"
        rules={[
          { required: true, message: 'Telephone number is required' },
          {
            validator: (_, value) => {
              if (!value || value.replace(/\D/g, '').length >= 6) {
                return Promise.resolve();
              }
              return Promise.reject('Telephone number must have at least 6 digits');
            }
          }
        ]}
        className="component-form-item w-full"
      >
        <PhoneInput
          country={'gb'}
          onlyCountries={['gb', 'ae']}
          value={form.getFieldValue('tel') || ''}
          onChange={(value) => handlePhoneChange(value, form)}
          disabled={!isEditing}
          inputProps={{
            name: 'tel',
            placeholder: 'Enter telephone number',
            style: { paddingLeft: '45px', height: "2rem" },
            className: `${inputClass} w-full`
          }}
          containerClass="w-full phone-input-container"
          buttonClass={isDarkMode ? 'dark-mode-phone' : ''}
          dropdownClass={isDarkMode ? 'dark-mode-dropdown' : ''}
        />
      </Form.Item>

      <Form.Item
        name="codes"
        label={<span className="component-label">Codes</span>}
        className="component-form-item"
      >
        <div className="space-y-2">
          <div className="flex flex-wrap gap-2">
            {(branch?.codes || []).map((code, index) => (
              <Tag
                key={index}
                closable={isEditing}
                onClose={() => {
                  onDeleteCode(index);
                  const currentCodes = [...(branch?.codes || [])];
                  currentCodes.splice(index, 1);
                  form.setFieldValue('codes', currentCodes);
                }}
                className={`component-tag ${isDarkMode ? 'dark-mode' : ''}`}
              >
                {code}
              </Tag>
            ))}
          </div>
          {isEditing && (
            <div className="flex items-center space-x-2">
              <Input
                value={newCode}
                onChange={(e) => setNewCode(e.target.value)}
                placeholder="Add new code"
                className={inputClass}
                style={{ height: '2rem' }}
              />
              <button
                onClick={handleAddCode}
                disabled={!isEditing}
                className="component-button component-button-primary"
              >
                <PlusOutlined />
              </button>
            </div>
          )}
        </div>
      </Form.Item>
    </>
  );

  return (
    <BaseDetailsForm
      title="Branch Information"
      isEditing={isEditing}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onEdit={onEdit}
      isDarkMode={isDarkMode}
      initialValues={initialValues}
      saveButtonText="Save Changes"
      editButtonText="Edit Branch"
    >
      {(form) => formContent(form)}
    </BaseDetailsForm>
  );
};

BranchDetailsForm.propTypes = {
  branch: PropTypes.shape({
    name: PropTypes.string,
    reference: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    town: PropTypes.string,
    county: PropTypes.string,
    country: PropTypes.string,
    zipPostCode: PropTypes.string,
    accountcode: PropTypes.string,
    tel: PropTypes.string,
    codes: PropTypes.arrayOf(PropTypes.string),
    company: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  }),
  isEditing: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool,
  onAddCode: PropTypes.func.isRequired,
  onDeleteCode: PropTypes.func.isRequired,
  newCode: PropTypes.string,
  setNewCode: PropTypes.func.isRequired
};

export default BranchDetailsForm;
