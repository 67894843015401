import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';
import { JsonEditor } from "../../../../components/misc/JsonEditor.jsx";
import "../../../../styles/common.css";

const AIPromptForm = ({
  model,
  editedJsonString,
  onJsonChange,
  onValidateClick,
  isDarkMode
}) => {
  const [jsonError, setJsonError] = useState('');
  const [outputJsonError, setOutputJsonError] = useState('');
  const [isJsonValid, setIsJsonValid] = useState(false);
  const [isOutputJsonValid, setIsOutputJsonValid] = useState(false);
  const [localJsonString, setLocalJsonString] = useState(editedJsonString);
  const [localOutputString, setLocalOutputString] = useState(model.outputFormat);
  const [isEditing, setIsEditing] = useState(false);
  const [narrativeHeight, setNarrativeHeight] = useState('400px');
  const [outputHeight, setOutputHeight] = useState('200px');

  const originalValues = useRef(null);
  const resizingNarrativeRef = useRef(false);
  const resizingOutputRef = useRef(false);
  const startHeightRef = useRef(0);
  const startYRef = useRef(0);

  useEffect(() => {
    if (!originalValues.current) {
      originalValues.current = {
        narrativeJson: editedJsonString,
        outputJson: model.outputFormat,
      };
    }
  }, [editedJsonString, model.outputFormat]);

  const handleEditClick = () => {
    setIsEditing(true);
    setJsonError('');
    setOutputJsonError('');
    setIsJsonValid(false);
    setIsOutputJsonValid(false);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setLocalJsonString(originalValues.current.narrativeJson);
    setLocalOutputString(originalValues.current.outputJson);
    setJsonError('');
    setOutputJsonError('');
    setIsJsonValid(false);
    setIsOutputJsonValid(false);
  };

  const handleJsonChange = (value, isNarrative = true) => {
    if (isNarrative) {
      setJsonError('');
      setIsJsonValid(false);
      setLocalJsonString(value);
      onJsonChange(value);
    } else {
      setOutputJsonError('');
      setIsOutputJsonValid(false);
      setLocalOutputString(value);
    }
  };

  const handleValidateClick = () => {
    let narrativeValid = false;
    let outputValid = false;

    try {
      JSON.parse(localJsonString);
      setIsJsonValid(true);
      setJsonError('');
      narrativeValid = true;
    } catch (error) {
      setJsonError(error.message);
      setIsJsonValid(false);
    }

    try {
      JSON.parse(localOutputString);
      setIsOutputJsonValid(true);
      setOutputJsonError('');
      outputValid = true;
    } catch (error) {
      setOutputJsonError(error.message);
      setIsOutputJsonValid(false);
    }

    if (narrativeValid && outputValid) {
      onValidateClick();
      setIsEditing(false);
    }
  };

  const handleMouseDown = (e, isNarrative = true) => {
    if (isNarrative) {
      resizingNarrativeRef.current = true;
      startHeightRef.current = parseInt(narrativeHeight);
    } else {
      resizingOutputRef.current = true;
      startHeightRef.current = parseInt(outputHeight);
    }
    startYRef.current = e.clientY;

    const handleMouseMove = (e) => {
      if (!resizingNarrativeRef.current && !resizingOutputRef.current) return;
      const deltaY = e.clientY - startYRef.current;
      const newHeight = Math.max(200, startHeightRef.current + deltaY);

      if (resizingNarrativeRef.current) {
        setNarrativeHeight(`${newHeight}px`);
      } else {
        setOutputHeight(`${newHeight}px`);
      }
    };

    const handleMouseUp = () => {
      resizingNarrativeRef.current = false;
      resizingOutputRef.current = false;
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const extractKeywords = (text) => {
    const regex = /\{\[(.*?)\]\}/g;
    const matches = [...text.matchAll(regex)];
    return matches.map(match => match[1]);
  };

  const allKeywords = new Set();
  try {
    const narrativeJson = JSON.parse(model.narrative);
    Object.values(narrativeJson).forEach(value => {
      if (typeof value === 'string') {
        extractKeywords(value).forEach(keyword => allKeywords.add(keyword));
      }
    });
  } catch (error) {
    console.error('Error parsing narrative JSON:', error);
  }

  const renderEditor = (title, content, error, isValid, height, handleResize, isNarrative = true) => (
    <div>
      <h3 className="text-lg font-semibold mb-2 dark:text-white">{title}</h3>
      <div className="relative" style={{ height }}>
        <JsonEditor
          editedJsonString={content}
          handleJsonChange={(value) => handleJsonChange(value, isNarrative)}
          isEditing={isEditing}
          isDarkMode={isDarkMode}
          jsonError={error}
          isJsonValid={isValid}
          handleValidateClick={handleValidateClick}
          editorHeight="100%"
          onSave={handleValidateClick}
          onCancel={handleCancelEdit}
          onEdit={handleEditClick}
          saveDisabled={!isValid}
        />
        <div
          className={`absolute bottom-0 right-0 w-4 h-4 cursor-se-resize ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}
          onMouseDown={(e) => handleResize(e, isNarrative)}
          style={{
            backgroundImage: 'linear-gradient(135deg, transparent 50%, currentColor 50%, currentColor 60%, transparent 60%)',
            backgroundSize: '8px 8px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right bottom'
          }}
        />
      </div>
    </div>
  );

  return (
    <div className={`component-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <Card
        className={`component-card ${isDarkMode ? 'dark-mode' : ''}`}
        title={
          <div className="component-header mt-3 mb-2">
            <span className="component-title">AI Prompt Configuration</span>
            <div className="space-x-2">
              {!isEditing ? (
                <button
                  onClick={handleEditClick}
                  className="component-button component-button-primary mr-2"
                >
                  Edit
                </button>
              ) : (
                <>
                  <button
                    onClick={handleCancelEdit}
                    className="component-button component-button-secondary"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleValidateClick}
                    className="component-button component-button-primary"
                    disabled={!(isJsonValid && isOutputJsonValid)}
                  >
                    Save
                  </button>
                </>
              )}
            </div>
          </div>
        }
      >
        <div className="component-content">
          <div className="space-y-4">
            <div className="space-y-2">
              <h3 className="text-lg font-semibold dark:text-white">Keywords</h3>
              <div className="flex flex-wrap gap-2">
                {Array.from(allKeywords).map((keyword, index) => (
                  <span
                    key={index}
                    className={`px-2 py-1 rounded ${
                      isDarkMode
                        ? 'bg-blue-700 text-white'
                        : 'bg-blue-100 text-blue-800'
                    }`}
                  >
                    {keyword}
                  </span>
                ))}
              </div>
            </div>

            {renderEditor(
              "Narrative",
              localJsonString,
              jsonError,
              isJsonValid,
              narrativeHeight,
              handleMouseDown,
              true
            )}

            {renderEditor(
              "Output Format",
              localOutputString,
              outputJsonError,
              isOutputJsonValid,
              outputHeight,
              handleMouseDown,
              false
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

AIPromptForm.propTypes = {
  model: PropTypes.shape({
    narrative: PropTypes.string,
    outputFormat: PropTypes.string
  }).isRequired,
  editedJsonString: PropTypes.string.isRequired,
  onJsonChange: PropTypes.func.isRequired,
  onValidateClick: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired
};

export default AIPromptForm;
