import { useState } from 'react';

export const useTableFilters = (initialFilters, filterLogic) => {
  const [filters, setFilters] = useState(initialFilters);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handleFilterChange = (property, value) => {
    setFilters(prev => ({
      ...prev,
      [property]: value
    }));
    setCurrentPage(1);
  };

  const filterData = (data) => {
    return data.filter(item => filterLogic(item, filters));
  };

  const getPaginatedData = (filteredData) => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return filteredData.slice(start, end);
  };

  return {
    filters,
    currentPage,
    itemsPerPage,
    handleFilterChange,
    setCurrentPage,
    setItemsPerPage,
    filterData,
    getPaginatedData
  };
};
