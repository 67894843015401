import React from 'react';
import { Form, Input, Card, Switch } from 'antd';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const VirtualAssistantDetailsForm = ({
  assistant,
  isEditing,
  onSubmit,
  onCancel,
  onEdit,
  isDarkMode,
}) => {
  const [form] = Form.useForm();
  const [companyName, setCompanyName] = React.useState('');

  React.useEffect(() => {
    if (assistant) {
      setCompanyName(assistant.company?.name || '');

      const formData = {
        name: assistant.name || '',
        phoneNumber: assistant.phoneNumber || '',
        note: assistant.note || '',
        isEmailEnabled: assistant.isEmailEnabled || false,
        isSmsEnabled: assistant.isSmsEnabled || false,
        emailSettings: {
          email: assistant.emailSettings?.email || '',
          signature: assistant.emailSettings?.signature || '',
          outOfOffice: assistant.emailSettings?.outOfOffice || '',
          outOfOfficeEnabled: assistant.emailSettings?.outOfOfficeEnabled || false,
          emailsIntervalInSeconds: assistant.emailSettings?.emailsIntervalInSeconds || 0,
          dailyEmailsLimit: assistant.emailSettings?.dailyEmailsLimit || 0,
        }
      };
      form.setFieldsValue(formData);
    }
  }, [assistant, form]);

  const handlePhoneChange = (value) => {
    form.setFieldValue('phoneNumber', value);
  };

  const inputClass = `component-input ${isDarkMode ? 'dark-mode' : ''}`;

  return (
    <div className={`component-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <Card
        className={`component-card ${isDarkMode ? 'dark-mode' : ''}`}
        title={
          <div className="component-header mt-3 mb-2">
            <span className="component-title">Virtual Assistant Information</span>
            <div className="space-x-2 mr-2">
              {isEditing ? (
                <>
                  <button
                    onClick={onCancel}
                    className="component-button component-button-secondary"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => form.submit()}
                    className="component-button component-button-primary"
                  >
                    Save Changes
                  </button>
                </>
              ) : (
                <button
                  onClick={onEdit}
                  className="component-button component-button-primary"
                >
                  Edit
                </button>
              )}
            </div>
          </div>
        }
      >
        <div className="component-content">
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            disabled={!isEditing}
            className="component-form"
          >
            <Form.Item
              label={<span className="component-label">Company Name</span>}
              className="component-form-item"
            >
              <Input
                value={companyName}
                disabled={true}
                className={inputClass}
              />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Assistant Name</span>}
              name="name"
              rules={[{ required: true, message: 'Assistant name is required' }]}
              className="component-form-item"
            >
              <Input className={inputClass} />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Phone Number</span>}
              name="phoneNumber"
              rules={[
                { required: true, message: 'Phone number is required' },
                {
                  validator: (_, value) => {
                    if (!value || value.replace(/\D/g, '').length >= 6) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Phone number must have at least 6 digits');
                  }
                }
              ]}
              className="component-form-item w-full"
            >
              <PhoneInput
                country={'gb'}
                onlyCountries={['gb', 'ae']}
                value={form.getFieldValue('phoneNumber') || ''}
                onChange={handlePhoneChange}
                disabled={!isEditing}
                inputProps={{
                  name: 'phoneNumber',
                  placeholder: 'Enter phone number',
                  style: { paddingLeft: '45px', height: "2rem" },
                  className: `${inputClass} w-full`
                }}
                containerClass="w-full phone-input-container"
                buttonClass={isDarkMode ? 'dark-mode-phone' : ''}
                dropdownClass={isDarkMode ? 'dark-mode-dropdown' : ''}
              />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Note</span>}
              name="note"
              className="component-form-item"
            >
              <Input.TextArea className={inputClass} rows={4} />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Email Settings</span>}
              className="component-form-item"
            >
              <div className="space-y-4">
                <Form.Item
                  name={['emailSettings', 'email']}
                  label={<span className="component-label">Email Address</span>}
                  rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
                >
                  <Input className={inputClass} />
                </Form.Item>

                <Form.Item
                  name={['emailSettings', 'signature']}
                  label={<span className="component-label">Email Signature</span>}
                >
                  <Input.TextArea className={inputClass} rows={3} />
                </Form.Item>

                <Form.Item
                  name={['emailSettings', 'emailsIntervalInSeconds']}
                  label={<span className="component-label">Emails Interval (seconds)</span>}
                >
                  <Input type="number" min={0} className={inputClass} />
                </Form.Item>

                <Form.Item
                  name={['emailSettings', 'dailyEmailsLimit']}
                  label={<span className="component-label">Daily Emails Limit</span>}
                >
                  <Input type="number" min={0} className={inputClass} />
                </Form.Item>
              </div>
            </Form.Item>

            <Form.Item
              name="isEmailEnabled"
              valuePropName="checked"
              label={<span className="component-label">Email Enabled</span>}
              className="component-form-item"
            >
              <Switch className={isDarkMode ? 'dark-mode' : ''} />
            </Form.Item>

            <Form.Item
              name="isSmsEnabled"
              valuePropName="checked"
              label={<span className="component-label">SMS Enabled</span>}
              className="component-form-item"
            >
              <Switch className={isDarkMode ? 'dark-mode' : ''} />
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

VirtualAssistantDetailsForm.propTypes = {
  assistant: PropTypes.shape({
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    note: PropTypes.string,
    isEmailEnabled: PropTypes.bool,
    isSmsEnabled: PropTypes.bool,
    emailSettings: PropTypes.shape({
      email: PropTypes.string,
      signature: PropTypes.string,
      outOfOffice: PropTypes.string,
      outOfOfficeEnabled: PropTypes.bool,
      emailsIntervalInSeconds: PropTypes.number,
      dailyEmailsLimit: PropTypes.number,
    }),
    company: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  }),
  isEditing: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool,
};

export default VirtualAssistantDetailsForm;
