import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, Outlet } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from './contexts/ThemeContext';
import LayoutWrapper from './components/layouts/LayoutWrapper';
import AuthenticationWrapper from './components/authorization/AuthWrapper';
import LogoutCallback from './components/misc/LogoutCallBack';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import DataPage from './pages/client/client';
import SystemDataPage from './pages/client/system';
import BranchesDataPage from './pages/client/branch';
import BranchDetailsPage from './pages/client/branch/details';
import AddBranchPage from './pages/client/branch/add';
import SystemsDetailPage from './pages/client/system/details';
import ProductDataPage from './pages/internal/products';
import ProductDetailPage from './pages/internal/products/details';
import AIMLDataPage from './pages/internal/ai';
import AIModelDetailPage from './pages/internal/ai/details';
import ProviderDataPage from './pages/internal/providers';
import ProviderDetailPage from './pages/internal/providers/details';
import VirtualAssistantDetailPage from './pages/client/virtual/details';
import ClientDetailsPage1 from './pages/client/client/details';
import CustomerDetailPage from './pages/client/customer/details';
import ConversationDetailPage from './pages/client/conversation/details';
import AddSystemPage from './pages/client/system/add';
import CallbackPage from './pages/callBack/CallBackPage';
import ErrorPage from './pages/error/ErrorPage';
import ErrorBoundary from './pages/error/ErrorBoundary';
import CustomerContent from './pages/reporting/CustomerContent.jsx'
import VehicleContent from 'pages/reporting/VehicleContent';
import TokenSetter from './utils/tokenSetter';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import store from './store';
import ProtectedRoute from './components/authorization/ProtectedRoutes';
import Unauthorized from './pages/unauthorized/UnauthorizedPage';
import EmailContentPage from './pages/reporting/EmailContent';
import TitleManager from './utils/RouteTitles.jsx';


const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };


  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "openid profile email offline_access read:current_user"  // Add this line
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheMemory="memory"
    >
      {children}
    </Auth0Provider>
  );
};

const App = () => {
  const [auth0Error] = useState(null);

  if (!process.env.REACT_APP_AUTH0_DOMAIN || !process.env.REACT_APP_AUTH0_CLIENT_ID) {
    console.error('Auth0 configuration is missing');
    return <div>Error: Auth0 configuration is missing. Please check your environment variables.</div>;
  }

  return (
    <Provider store={store}>
      <Router>
        <Auth0ProviderWithNavigate>
          <ThemeProvider>
            <TitleManager />
            <AuthenticationWrapper>
              <TokenSetter>
                {auth0Error ? (
                  <div>Authentication Error: {auth0Error}</div>
                ) : (
                  <ErrorBoundary>
                    <Routes>
                      <Route path="/" element={<LayoutWrapper />}>
                        <Route
                            index
                            element={
                              <ProtectedRoute requiredRoles={['Administrator', 'IU-Operations']}>
                                <Dashboard />
                              </ProtectedRoute>
                            }
                        />
                        <Route path="settings" element={<Settings />} />

                        <Route path="client-admin" element={
                          <ProtectedRoute requiredRoles={['Administrator', 'IU-Operations']}>
                            <Outlet />
                          </ProtectedRoute>
                        }>
                          <Route path="client" element={<DataPage />} />
                          <Route path="clients/:id" element={<ClientDetailsPage1 />} />
                          <Route path="systems" element={<SystemDataPage />} />
                          <Route path="systems/:id" element={<SystemsDetailPage />} />
                          <Route path="systems/add" element={<AddSystemPage />} />
                          <Route path="branches" element={<BranchesDataPage />} />
                          <Route path="branches/:id" element={<BranchDetailsPage />} />
                          <Route path="branches/add" element={<AddBranchPage />} />
                          <Route path="virtual-assistants/:id" element={<VirtualAssistantDetailPage />} />
                          <Route path="customer/:contactId" element={<CustomerDetailPage />} />
                          <Route path="conversation/:id" element={<ConversationDetailPage />} />
                        </Route>

                        <Route path="internal-admin" element={
                          <ProtectedRoute requiredRoles={['Administrator', 'IU-Operations']}>
                            <Outlet />
                          </ProtectedRoute>
                        }>
                          <Route path="products" element={<ProductDataPage />} />
                          <Route path="products/:id" element={<ProductDetailPage />} />
                          <Route path="AIML" element={<AIMLDataPage />} />
                          <Route path="aiml-models/:id" element={<AIModelDetailPage />} />
                          <Route path="providers" element={<ProviderDataPage />} />
                          <Route path="providers/:id" element={<ProviderDetailPage />} />
                        </Route>
                      </Route>

                      <Route
                        path="customer-reporting/:id"
                        element={
                          <ProtectedRoute requiredPermissions={['reporting:view_customer']}>
                            <CustomerContent />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="vehicle-reporting/:id"
                        element={
                          <ProtectedRoute requiredPermissions={['reporting:view_vehicle']}>
                            <VehicleContent />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="email-reporting/:contactId"
                        element={
                          <ProtectedRoute requiredPermissions={['reporting:view_messages']}>
                            <EmailContentPage />
                          </ProtectedRoute>
                        }
                      />

                      <Route path="/unauthorized" element={<Unauthorized />} />
                      <Route path="error" element={<ErrorPage />} />
                      <Route path="/callback" element={<CallbackPage />} />
                      <Route path="/logout-callback" element={<LogoutCallback />} />
                    </Routes>
                  </ErrorBoundary>
                )}
              </TokenSetter>
            </AuthenticationWrapper>
          </ThemeProvider>
        </Auth0ProviderWithNavigate>
      </Router>
    </Provider>
  );
}

Auth0ProviderWithNavigate.propTypes = {
   children: PropTypes.node
};

export default App;
