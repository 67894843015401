import React from 'react';
import { Form, Card } from 'antd';
import PropTypes from 'prop-types';

const BaseDetailsForm = ({
  title,
  isEditing,
  onSubmit,
  onCancel,
  onEdit,
  isDarkMode,
  initialValues,
  children,
  saveButtonText = 'Save',
  editButtonText = 'Edit',
  cancelButtonText = 'Cancel'
}) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  return (
    <div className={`component-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <Card
        className={`component-card ${isDarkMode ? 'dark-mode' : ''}`}
        title={
          <div className="component-header mt-3 mb-2">
            <span className="component-title">{title}</span>
            <div className="space-x-2">
              {isEditing ? (
                <>
                  <button
                    onClick={onCancel}
                    className="component-button component-button-secondary"
                  >
                    {cancelButtonText}
                  </button>
                  <button
                    onClick={() => form.submit()}
                    className="component-button component-button-primary"
                  >
                    {saveButtonText}
                  </button>
                </>
              ) : (
                <button
                  onClick={onEdit}
                  className="component-button component-button-primary"
                >
                  {editButtonText}
                </button>
              )}
            </div>
          </div>
        }
      >
        <div className="component-content">
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            disabled={!isEditing}
            className="component-form"
          >
            {typeof children === 'function' ? children(form) : children}
          </Form>
        </div>
      </Card>
    </div>
  );
};

BaseDetailsForm.propTypes = {
  title: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  children: PropTypes.node.isRequired,
  saveButtonText: PropTypes.string,
  editButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string
};

export default BaseDetailsForm;
