import React from 'react';
import { Form, Input, Switch } from 'antd';
import PropTypes from 'prop-types';
import UserInfo from '../../../../components/basic/UserInfo';
import BaseDetailsForm from '../../../../components/basic/BaseDetailsForm';

const ProductDetailsForm = ({
  product,
  isEditing,
  onSubmit,
  onCancel,
  onEdit,
  isDarkMode
}) => {
  const formContent = (
    <>
      <Form.Item
        label={<span className="component-label">Name</span>}
        name="name"
        rules={[{ required: true, message: 'Name is required' }]}
        className="component-form-item"
      >
        <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">AI Enabled</span>}
        name="aiEnabled"
        valuePropName="checked"
        className="component-form-item"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">Goal</span>}
        name="goal"
        rules={[{ required: true, message: 'Goal is required' }]}
        className="component-form-item"
      >
        <Input.TextArea
          className={`component-input ${isDarkMode ? 'dark-mode' : ''}`}
          rows={4}
        />
      </Form.Item>

      <Form.Item
        label={<span className="component-label">Labels</span>}
        name="labels"
        className="component-form-item"
      >
        <Input.TextArea
          className={`component-input ${isDarkMode ? 'dark-mode' : ''}`}
          rows={4}
        />
      </Form.Item>

      <div className="border-t border-gray-200 dark:border-gray-600 mt-6 pt-4">
        <div className="grid grid-cols-2 gap-6">
          <UserInfo
            label="Created By"
            user={product.createdByUser}
            date={product.createdDate}
          />
          <UserInfo
            label="Updated By"
            user={product.updatedByUser}
            date={product.updatedDate}
          />
        </div>
      </div>
    </>
  );

  return (
    <BaseDetailsForm
      title="Product Details"
      isEditing={isEditing}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onEdit={onEdit}
      isDarkMode={isDarkMode}
      initialValues={product}
    >
      {formContent}
    </BaseDetailsForm>
  );
};

ProductDetailsForm.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    goal: PropTypes.string,
    labels: PropTypes.string,
    aiEnabled: PropTypes.bool,
    createdByUser: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    updatedByUser: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    createdDate: PropTypes.string,
    updatedDate: PropTypes.string
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired
};

export default ProductDetailsForm;
