import React from 'react';
import PropTypes from 'prop-types';
import { getProductName } from '../../constants/ProductTypeConverter.jsx';

const TagsDisplayBar = ({ tags = [] }) => {
  if (!tags || tags.length === 0) return null;

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-4 mb-4">
      <div className="flex flex-wrap gap-2">
        {tags.map((tag, index) => (
          <span
            key={index}
            className="px-3 py-1 bg-green-500 text-white rounded-full text-sm font-medium">
            {getProductName(tag) || tag}
          </span>
        ))}
      </div>
    </div>
  );
};

TagsDisplayBar.propTypes = {
  tags: PropTypes.array

}

export default TagsDisplayBar;
