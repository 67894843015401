import React from "react";
import "./LoadingSpinner.css";

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-full">
    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900 dark:border-gray-100"></div>
  </div>
);

export default LoadingSpinner;
