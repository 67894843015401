import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { grabToken } from '../../../utils/api';
import { useTheme } from '../../../contexts/ThemeContext';
import { notification } from 'antd';
import ErrorPage from '../../error/ErrorPage';
import LoadingSpinner from '../../../components/misc/LoadingSpinner';
import usePrompt from '../../../hooks/usePrompt';
import EmailProviderDetailsForm from './components/EmailProviderDetailsForm';
import {
  NavigationHeader,
  useFormHandlers,
  handleBackNavigation,
  getCommonStyles,
  confirmNavigation,
  handleApiError
} from '../../../components/helpers/CommonHelper';

const API_CONFIG = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  endpoints: {
    providers: '/EmailProviders',
  },
};

const ProviderDetailPage = () => {
  const [provider, setProvider] = useState({
    id: '',
    name: '',
    enforceGraphApi: false,
    createdDate: '',
    updatedDate: '',
    createdByUser: { id: '', name: '' },
    updatedByUser: { id: '', name: '' },
    sender: { server: '', port: 0, sslEnabled: false },
    recipient: { server: '', port: 0, sslEnabled: false },
    state: 0,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const originalValues = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const location = useLocation();

  const { containerClass } = getCommonStyles(isDarkMode);

  const {
    isEditing,
    setIsEditing,
    handleEditClick,
    handleCancelEdit,
  } = useFormHandlers({
    initialData: provider,
    fetchDetails: () => fetchProviderDetails(id),
    onSave: (name, value) => {
      setProvider(prev => ({ ...prev, [name]: value }));
      setHasUnsavedChanges(true);
    },
    hasUnsavedChanges
  });

  useEffect(() => {
    if (id) {
      fetchProviderDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (provider && !originalValues.current) {
      originalValues.current = { ...provider };
    }
  }, [provider]);

  usePrompt('You have unsaved changes. Are you sure you want to leave?', hasUnsavedChanges && isEditing);

  const handleBack = () => {
    confirmNavigation(hasUnsavedChanges, (shouldNavigate) => {
      if (shouldNavigate) {
        handleBackNavigation(navigate, location, '/internal-admin/providers');
      }
    });
  };

  const fetchProviderDetails = async (providerId) => {
    setLoading(true);
    setError(null);
    try {
      const token = await grabToken();
      const response = await axios.get(`${API_CONFIG.baseURL}${API_CONFIG.endpoints.providers}/${providerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
      setProvider(response.data.result);
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (formValues) => {
    setLoading(true);
    try {
      const token = await grabToken();
      const payload = {
        ...provider,
        ...formValues
      };

      await axios.put(
        `${API_CONFIG.baseURL}${API_CONFIG.endpoints.providers}/${provider.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setProvider(payload);
      setLoading(false);
      setHasUnsavedChanges(false);
      setIsEditing(false);
      notification.success({
        message: 'Provider was updated',
      });

      await fetchProviderDetails(payload.id);
    } catch (error) {
      setLoading(false);
      const errorMessage = handleApiError(error);
      setError(errorMessage);
      notification.error({
        message: 'Provider update failed',
        description: errorMessage,
      });
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorPage isDarkMode={isDarkMode} errorMessage={error} />;

  return (
    <div className={containerClass}>
      <div className="container mx-auto p-6">
        <NavigationHeader
          title={`Email Provider Details | ${provider.name}`}
          onBackClick={handleBack}
          isDarkMode={isDarkMode}
        />

          <EmailProviderDetailsForm
            provider={provider}
            isEditing={isEditing}
            onSubmit={handleSubmit}
            onCancel={handleCancelEdit}
            onEdit={handleEditClick}
            isDarkMode={isDarkMode}
          />
      </div>
    </div>
  );
};

export default ProviderDetailPage;
