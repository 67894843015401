import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { grabToken } from '../../../utils/api';
import { useTheme } from '../../../contexts/ThemeContext';
import { v4 as uuidv4 } from 'uuid';
import { Form, Input, Card, Select, notification } from 'antd';
import ErrorPage from '../../error/ErrorPage';
import LoadingSpinner from '../../../components/misc/LoadingSpinner';
import { JsonEditor } from '../../../components/misc/JsonEditor';
import { NavigationHeader } from '../../../components/helpers/CommonHelper';

const validateField = (name, value) => {
  switch (name) {
    case 'name':
      return value.trim() === '' ? 'Name is required' : '';
    case 'description':
      return value.trim() === '' ? 'Description is required' : '';
    default:
      return '';
  }
};

const AddSystemPage = () => {
  const [form] = Form.useForm();
  const [system, setSystem] = useState({
    companyId: '',
    name: '',
    description: '',
    guid: '',
    processMotcheck: false,
    foreignKey: '0',
    type: 1,
    ipAddress: '',
    databaseUserName: '',
    properties: '{}',
    state: 0
  });

  const [setErrors] = useState({});
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editedJsonString, setEditedJsonString] = useState('{}');
  const [isJsonValid, setIsJsonValid] = useState(true);
  const [jsonError, setJsonError] = useState(null);
  const [editorHeight, setEditorHeight] = useState('720px');
  const formCardRef = useRef(null);

  useEffect(() => {
    fetchCompanyList();
  }, []);

  useEffect(() => {
    const updateEditorHeight = () => {
      if (formCardRef.current) {
        const formHeight = formCardRef.current.offsetHeight;
        setEditorHeight(`${formHeight}px`);
      }
    };

    updateEditorHeight();
    window.addEventListener('resize', updateEditorHeight);

    return () => window.removeEventListener('resize', updateEditorHeight);
  }, []);

  const fetchCompanyList = async () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    try {
      const token = await grabToken();
      const response = await axios.get(`${apiBaseUrl}/Companies/filter`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        params: {
          pageSize: 100
        }
      });

      if (response.data && response.data.result && Array.isArray(response.data.result.list)) {
        setCompanies(response.data.result.list);
      } else {
        setError('Received data is not in the expected format');
      }
    } catch (error) {
      setError(error.response ? `Error: ${error.response.status} - ${error.response.data}` : error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleJsonChange = (value) => {
    setEditedJsonString(value);
    try {
      JSON.parse(value);
      setIsJsonValid(true);
      setJsonError(null);
    } catch (error) {
      setIsJsonValid(false);
      setJsonError(error.message);
    }
  };

  const handleValidateClick = () => {
    try {
      const parsed = JSON.parse(editedJsonString);
      const formatted = JSON.stringify(parsed, null, 2);
      setEditedJsonString(formatted);
      setIsJsonValid(true);
      setJsonError(null);
      notification.success({
        message: 'JSON is valid',
        description: 'The JSON has been validated and formatted.',
      });
    } catch (error) {
      setIsJsonValid(false);
      setJsonError(error.message);
      notification.error({
        message: 'Invalid JSON',
        description: error.message,
      });
    }
  };

  const handleGenerateGuid = () => {
    const newGuid = uuidv4();
    form.setFieldsValue({ guid: newGuid });
    setSystem(prev => ({
      ...prev,
      guid: newGuid
    }));
  };

  const handleSubmit = async (values) => {
    try {
      const formErrors = Object.keys(values).reduce((acc, key) => {
        const fieldError = validateField(key, values[key]);
        if (fieldError) {
          acc[key] = fieldError;
        }
        return acc;
      }, {});

      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        return;
      }

      if (!isJsonValid) {
        notification.error({
          message: 'Invalid JSON',
          description: 'Please fix the JSON errors before submitting.',
        });
        return;
      }

      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = await grabToken();

      const selectedCompany = companies.find(company => company.id === values.companyId);

      const requestBody = {
        ...values,
        properties: editedJsonString,
        company: {
          id: selectedCompany.id,
          name: selectedCompany.name
        }
      };

      const response = await axios.post(
        `${apiBaseUrl}/Systems`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        notification.success({
          message: 'System Created',
          description: 'The system has been successfully created.',
        });
        navigate('/client-admin/systems');
      }
    } catch (error) {
      console.error('Error adding system:', error);
      notification.error({
        message: 'Error Creating System',
        description: error.response
          ? `Error: ${error.response.status} - ${JSON.stringify(error.response.data)}`
          : error.message,
      });
    }
  };

  const handleBack = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else {
      navigate('/client-admin/systems');
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorPage isDarkMode={isDarkMode} errorMessage={error} />;

  return (
    <div className={`component-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="container mx-auto p-6 dark:text-white">
        <NavigationHeader
          title="Add New System"
          onBackClick={handleBack}
          isDarkMode={isDarkMode}
        />

        <div className="space-y-4">
          <div className="flex space-x-4">
            <div className="w-1/2">
              <Card
                ref={formCardRef}
                className={`component-card ${isDarkMode ? 'dark-mode' : ''}`}
                title={
                  <div className="component-header">
                    <span className="component-title mt-3">System Information</span>
                  </div>
                }
              >
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handleSubmit}
                  initialValues={system}
                  className="component-form"
                >
                  <Form.Item
                    label={<span className="component-label">Company</span>}
                    name="companyId"
                    rules={[{ required: true, message: 'Company is required' }]}
                    className="component-form-item"
                  >
                    <Select
                      className={`component-select text-gray-800 dark:text-white ${isDarkMode ? 'dark-mode' : ''}`}
                      placeholder="Select Company"
                      popupClassName={isDarkMode ? 'dark-mode-select-dropdown' : ''}
                    >
                      {companies.map((company) => (
                        <Select.Option key={company.id} value={company.id}>
                          {company.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label={<span className="component-label">Name</span>}
                    name="name"
                    rules={[{ required: true, message: 'Name is required' }]}
                    className="component-form-item"
                  >
                    <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
                  </Form.Item>

                  <Form.Item
                    label={<span className="component-label">Description</span>}
                    name="description"
                    rules={[{ required: true, message: 'Description is required' }]}
                    className="component-form-item"
                  >
                    <Input.TextArea
                      className={`component-input ${isDarkMode ? 'dark-mode' : ''}`}
                      rows={3}
                    />
                  </Form.Item>

                  <Form.Item
                    label={<span className="component-label">GUID</span>}
                    name="guid"
                    className="component-form-item"
                  >
                    <div className="flex space-x-2">
                      <Input
                        className={`component-input ${isDarkMode ? 'dark-mode' : ''}`}
                        placeholder="Generate a GUID"
                        readOnly
                        value={system.guid}
                      />
                      <button
                        type="button"
                        onClick={handleGenerateGuid}
                        className="component-button component-button-primary whitespace-nowrap h-[32px]"
                      >
                        Generate
                      </button>
                    </div>
                  </Form.Item>

                  <Form.Item
                    label={<span className="component-label">Foreign Key</span>}
                    name="foreignKey"
                    className="component-form-item"
                  >
                    <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
                  </Form.Item>

                  <Form.Item
                    label={<span className="component-label">Type</span>}
                    name="type"
                    className="component-form-item"
                  >
                    <Input type="number" className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
                  </Form.Item>

                  <Form.Item
                    label={<span className="component-label">IP Address</span>}
                    name="ipAddress"
                    className="component-form-item"
                  >
                    <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
                  </Form.Item>

                  <Form.Item
                    label={<span className="component-label">Database Username</span>}
                    name="databaseUserName"
                    className="component-form-item"
                  >
                    <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
                  </Form.Item>

                  <Form.Item className="component-form-item">
                    <button
                      type="submit"
                      className="component-button component-button-primary"
                    >
                      Add System
                    </button>
                  </Form.Item>
                </Form>
              </Card>
            </div>

            <div className="w-1/2">
              <Card
                className={`component-card ${isDarkMode ? 'dark-mode' : ''}`}
                bodyStyle={{ padding: 0 }}
              >
                <div style={{ height: editorHeight }}>
                  <JsonEditor
                    editedJsonString={editedJsonString}
                    handleJsonChange={handleJsonChange}
                    isEditing={true}
                    isDarkMode={isDarkMode}
                    jsonError={jsonError}
                    isJsonValid={isJsonValid}
                    handleValidateClick={handleValidateClick}
                    editorHeight="100%"
                    onSave={() => {}}
                    onCancel={() => {}}
                    onEdit={() => {}}
                    saveDisabled={!isJsonValid}
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSystemPage;
