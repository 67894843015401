import React, { useState, useEffect } from 'react';
import {
  Users,
  Split,
  FileText,
  Brain,
  User,
  Mail
} from 'lucide-react';

const Dashboard = () => {
  const [activeItem, setActiveItem] = useState(null);
  const [eyePosition, setEyePosition] = useState({ x: 0, y: 0 });
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  useEffect(() => {
    const eyeElement = document.getElementById('eye');
    if (eyeElement) {
      const rect = eyeElement.getBoundingClientRect();
      const eyeCenterX = rect.left + rect.width / 2;
      const eyeCenterY = rect.top + rect.height / 2;

      const angle = Math.atan2(mousePosition.y - eyeCenterY, mousePosition.x - eyeCenterX);

      const distance = Math.min(5, Math.hypot(mousePosition.x - eyeCenterX, mousePosition.y - eyeCenterY) / 20);
      const x = Math.cos(angle) * distance;
      const y = Math.sin(angle) * distance;

      setEyePosition({ x, y });
    }
  }, [mousePosition]);

  const navItems = [
    { icon: User, label: 'Client', href: 'client-admin/client' },
    { icon: Users, label: 'System', href: 'client-admin/systems' },
    { icon: Split, label: 'Branches', href: 'client-admin/branches' },
    { icon: FileText, label: 'Products', href: 'internal-admin/products' },
    { icon: Brain, label: 'AI/ML Models', href: 'internal-admin/AIML' },
    { icon: Mail, label: 'Providers', href: 'internal-admin/providers' },
  ];

  return (
    <div className="min-h-screen flex flex-col items-center bg-gradient-to-br from-blue-50 to-purple-50 dark:from-gray-900 dark:to-gray-800 px-4 py-8 md:px-8">
      <div className="bg-white/90 backdrop-blur-sm dark:bg-gray-700 shadow-xl rounded-lg p-6 sm:p-8 md:p-12 w-full max-w-4xl transition-all duration-300 hover:shadow-2xl relative">
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400 text-center flex items-center justify-center">
          Welcome to Vision
          <div
            id="eye"
            className="inline-flex w-6 h-6 rounded-full bg-white dark:bg-gray-600 shadow-inner items-center justify-center mx-0.5 ring-2 ring-blue-600/50 dark:ring-0"
          >
            <div
              className="w-4 h-4 rounded-full bg-blue-600 dark:bg-blue-400 relative"
              style={{
                transform: `translate(${eyePosition.x}px, ${eyePosition.y}px)`,
                transition: 'transform 0.1s ease-out'
              }}
            >
              <div className="absolute top-1 left-1 w-1.5 h-1.5 rounded-full bg-white"></div>
            </div>
          </div>
        </h1>
        <p className="text-base sm:text-lg md:text-xl text-gray-700 dark:text-gray-300 text-center">
        </p>
      </div>

      <nav className="bg-white/90 backdrop-blur-sm dark:bg-gray-700 shadow-xl rounded-lg p-6 w-full max-w-4xl mt-8 transition-all duration-300 hover:shadow-2xl">
        <ul className="space-y-2">
          {navItems.map((item, index) => {
            const Icon = item.icon;
            return (
              <li key={index}>
                <a
                  href={item.href}
                  className="flex items-center space-x-3 p-3 rounded-lg transition-all duration-300 text-gray-800 dark:text-white relative overflow-hidden group hover:bg-gray-50 dark:hover:bg-gray-600"
                  onMouseEnter={() => setActiveItem(index)}
                  onMouseLeave={() => setActiveItem(null)}
                >
                  <div className="relative flex items-center space-x-3">
                    <Icon className={`w-5 h-5 transition-all duration-300 ${activeItem === index ? 'scale-110 text-blue-600 dark:text-blue-400' : ''}`} />
                    <span className={`transition-all duration-300 ${activeItem === index ? 'translate-x-1 font-medium' : ''}`}>
                      {item.label}
                    </span>
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Dashboard;
