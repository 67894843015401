import React from 'react';
import { Form, Input, Card } from 'antd';
import PropTypes from 'prop-types';
import UserInfo from '../../../../components/basic/UserInfo';

const AIModelDetailsForm = ({
  model,
  isEditing,
  onSubmit,
  onCancel,
  onEdit,
  isDarkMode
}) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldsValue(model);
  }, [model, form]);

  const getTypeDisplay = (type) => {
    switch (type) {
      case 15:
        return "AI Reply Prediction";
      case 16:
        return "AI Labels Prediction";
      default:
        return type.toString();
    }
  };

  return (
    <div className={`component-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <Card
        className={`component-card ${isDarkMode ? 'dark-mode' : ''}`}
        title={
          <div className="component-header mt-3 mb-2">
            <span className="component-title">AI Model Details</span>
            <div className="space-x-2">
              {isEditing ? (
                <>
                  <button
                    onClick={onCancel}
                    className="component-button component-button-secondary"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => form.submit()}
                    className="component-button component-button-primary"
                  >
                    Save
                  </button>
                </>
              ) : (
                <button
                  onClick={onEdit}
                  className="component-button component-button-primary mr-2"
                >
                  Edit
                </button>
              )}
            </div>
          </div>
        }
      >
        <div className="component-content">
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            disabled={!isEditing}
            initialValues={model}
            className="component-form"
          >
            <Form.Item
              label={<span className="component-label">Name</span>}
              name="note"
              rules={[{ required: true, message: 'Name is required' }]}
              className="component-form-item"
            >
              <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Model Type</span>}
              className="component-form-item"
            >
              <Input
                value={getTypeDisplay(model.type)}
                disabled
                className={`component-input ${isDarkMode ? 'dark-mode' : ''}`}
              />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Country Code</span>}
              name="countryCode"
              rules={[{ required: true, message: 'Country Code is required' }]}
              className="component-form-item"
            >
              <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
            </Form.Item>

            <Form.Item
              label={<span className="component-label">Model</span>}
              name="targetName"
              rules={[{ required: true, message: 'Model is required' }]}
              className="component-form-item"
            >
              <Input className={`component-input ${isDarkMode ? 'dark-mode' : ''}`} />
            </Form.Item>

            <div className="border-t border-gray-200 dark:border-gray-600 mt-6 pt-4">
              <div className="grid grid-cols-2 gap-6">
                <UserInfo
                  label="Created By"
                  user={model.createdByUser}
                  date={model.createdDate}
                />
                <UserInfo
                  label="Updated By"
                  user={model.updatedByUser}
                  date={model.updatedDate}
                />
              </div>
            </div>
          </Form>
        </div>
      </Card>
    </div>
  );
};

AIModelDetailsForm.propTypes = {
  model: PropTypes.shape({
    note: PropTypes.string,
    type: PropTypes.number,
    countryCode: PropTypes.string,
    targetName: PropTypes.string,
    createdByUser: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    updatedByUser: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    createdDate: PropTypes.string,
    updatedDate: PropTypes.string
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired
};

export default AIModelDetailsForm;
