import React from 'react';
import Header from '../../components/layouts/HeaderWithoutSideBar';
import { useTheme } from '../../contexts/ThemeContext';

const Unauthorized = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`min-h-screen flex flex-col ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <Header />
      <div className="flex flex-grow items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold mb-4">Unauthorized Access</h1>
          <p className="text-lg">You do not have permission to view this page.</p>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
