import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Modal } from 'antd';
import "../../styles/Tabs.css";
import { format, parseISO } from 'date-fns';

export const formatCountryCode = (code) => {
  if (!code) return '';

  const countryMap = {
    'uk': 'United Kingdom',
    'uae': 'United Arab Emirates',
    "ae": "United Arab Emirates"
  };

  return countryMap[code.toLowerCase()] || code;
};


export const getPageNumbers = (currentPage, total) => {
  const visiblePages = 3;
  const pages = [];

  if (total <= visiblePages + 2) {
    for (let i = 1; i <= total; i++) {
      pages.push(i);
    }
  } else {
    pages.push(1);
    let start = Math.max(currentPage - Math.floor(visiblePages / 2), 2);
    let end = Math.min(start + visiblePages - 1, total - 1);

    if (end - start < visiblePages - 1) {
      start = Math.max(end - visiblePages + 1, 2);
    }
    if (start > 2) {
      pages.push('...');
    }

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    if (end < total - 1) {
      pages.push('...');
    }
    pages.push(total);
  }
  return pages;
};


export const TabNavigation = ({ tabs, activeTab, onTabChange, isDarkMode }) => {
  const items = tabs.map(tab => ({
    key: tab.id,
    label: (
      <span className="tab-label inline-flex items-center gap-2">
        {tab.icon}
        <span>{tab.label}</span>
      </span>
    )
  }));
  return (
    <Tabs
      activeKey={activeTab}
      onChange={onTabChange}
      items={items}
      className={`custom-tabs ${isDarkMode ? 'dark' : ''}`}
      type="card"
      size="large"
      animated={true}
    />
  );
};

TabNavigation.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired
};

export const ActionButtons = ({
  isEditing,
  onSave,
  onCancel,
  onEdit,
  isDarkMode,
  saveDisabled = false,
  saveText = "Save Changes",
  editText = "Edit",
  cancelText = "Cancel"
}) => {
  return (
    <div className="flex justify-between">
      {isEditing ? (
        <>
          <button
            type="submit"
            disabled={saveDisabled}
            onClick={onSave}
            className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
            } ${saveDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {saveText}
          </button>
          <button
            type="button"
            onClick={onCancel}
            className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
                : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
            }`}
          >
            {cancelText}
          </button>
        </>
      ) : (
        <button
          type="button"
          onClick={onEdit}
          className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
            isDarkMode
              ? 'bg-blue-500 hover:bg-blue-600 focus:ring-yellow-500 text-white'
              : 'bg-blue-600 hover:bg-blue-700 focus:ring-yellow-400 text-white'
          }`}
        >
          {editText}
        </button>
      )}
    </div>
  );
};

ActionButtons.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  saveDisabled: PropTypes.bool,
  saveText: PropTypes.string,
  editText: PropTypes.string,
  cancelText: PropTypes.string
};

export const NavigationHeader = ({ title, onBackClick, isDarkMode }) => (
  <div className="flex justify-between items-center mb-6">
    <h1 className="text-2xl font-bold">{title}</h1>
    <button
      type="button"
      onClick={onBackClick}
      className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
        isDarkMode
          ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
          : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
      }`}
    >
      Back
    </button>
  </div>
);

NavigationHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired
};

export const useFormHandlers = ({
  fetchDetails,
  onSave,
  validateFields,
  initialData,
  groups = ['main']
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [unsavedChanges, setUnsavedChanges] = React.useState(
    Object.fromEntries(groups.map(group => [group, {}]))
  );
  const [originalValues, setOriginalValues] = React.useState(
    Object.fromEntries(groups.map(group => [group, {}]))
  );

  React.useEffect(() => {
    if (initialData && isEditing) {
      setOriginalValues(prev => ({
        ...prev,
        main: { ...initialData }
      }));
    }
  }, [initialData, isEditing]);

  const hasChanges = (group) => {
    const changes = unsavedChanges[group];
    return Object.keys(changes).length > 0;
  };

  const hasAnyChanges = () => {
    return groups.some(group => hasChanges(group));
  };

  const getChangedFields = (group) => {
    return Object.keys(unsavedChanges[group]);
  };

  const trackChange = (group, field, value) => {
    if (JSON.stringify(originalValues[group]?.[field]) === JSON.stringify(value)) {
      setUnsavedChanges(prev => {
        const newChanges = { ...prev[group] };
        delete newChanges[field];
        return { ...prev, [group]: newChanges };
      });
    } else {
      setUnsavedChanges(prev => ({
        ...prev,
        [group]: { ...prev[group], [field]: value }
      }));
    }
  };

  const resetChanges = (group) => {
    setUnsavedChanges(prev => ({
      ...prev,
      [group]: {}
    }));
  };

  const resetAllChanges = () => {
    setUnsavedChanges(
      Object.fromEntries(groups.map(group => [group, {}]))
    );
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    setIsEditing(true);
    resetAllChanges();
  };

  const handleCancelEdit = () => {
    if (hasAnyChanges()) {
      const changedGroups = groups.filter(group => hasChanges(group));
      const changeDetails = changedGroups.map(group => {
        const fields = getChangedFields(group);
        return `${group}: ${fields.join(', ')}`;
      }).join('\n');

      Modal.confirm({
        title: 'Unsaved Changes',
        content: (
          <div>
            <p>You have unsaved changes in the following sections:</p>
            <pre className="mt-2 text-sm text-gray-600 dark:text-gray-400">
              {changeDetails}
            </pre>
            <p className="mt-2">Are you sure you want to discard these changes?</p>
          </div>
        ),
        okText: 'Discard Changes',
        cancelText: 'Keep Editing',
        onOk: () => {
          setIsEditing(false);
          resetAllChanges();
          fetchDetails();
        },
      });
    } else {
      setIsEditing(false);
      fetchDetails();
    }
  };

  const handleInputChange = (e, group = 'main') => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    if (validateFields) {
      const fieldError = validateFields(name, newValue);
      setErrors(prev => ({
        ...prev,
        [name]: fieldError
      }));
    }

    trackChange(group, name, newValue);
    onSave(name, newValue);
  };

  const handleFieldChange = (group, field, value) => {
    trackChange(group, field, value);
    onSave(field, value);
  };

  return {
    isEditing,
    setIsEditing,
    errors,
    setErrors,
    unsavedChanges,
    hasChanges,
    hasAnyChanges,
    getChangedFields,
    handleEditClick,
    handleCancelEdit,
    handleInputChange,
    handleFieldChange,
    trackChange,
    resetChanges,
    resetAllChanges
  };
};



export const handleBackNavigation = (navigate, location, defaultPath) => {
  if (location.state?.from) {
    navigate(location.state.from);
  } else if (window.history.length > 2) {
    navigate(-1);
  } else {
    navigate(defaultPath);
  }
};


export const getCommonStyles = (isDarkMode) => ({
  inputClass: `w-full px-3 py-2 rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
    isDarkMode
      ? 'bg-gray-700 border-gray-600 focus:border-blue-300 focus:ring-blue-200 text-white'
      : 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200'
  }`,
  errorClass: 'text-red-500 text-sm mt-1',
  containerClass: `flex flex-col ${isDarkMode ? 'dark bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'}`
});

export const confirmNavigation = (unsavedChanges, callback) => {
  const hasChanges = typeof unsavedChanges === 'object'
    ? Object.values(unsavedChanges).some(group => Object.keys(group).length > 0)
    : false;

  if (hasChanges) {
    const changeDetails = Object.entries(unsavedChanges)
      .filter(([changes]) => Object.keys(changes).length > 0)
      .map(([group, changes]) => `${group}: ${Object.keys(changes).join(', ')}`)
      .join('\n');

    Modal.confirm({
      title: 'Unsaved Changes',
      content: (
        <div>
          <p>You have unsaved changes in the following sections:</p>
          <pre className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            {changeDetails}
          </pre>
          <p className="mt-2">Are you sure you want to leave?</p>
        </div>
      ),
      okText: 'Leave',
      cancelText: 'Stay',
      onOk: () => callback(true),
      onCancel: () => callback(false)
    });
  } else {
    callback(true);
  }
};


export const trimName = (name, maxLength = 30) => {
  if (!name) return 'N/A';
  return name.length > maxLength ? `${name.substring(0, maxLength)}...` : name;
};



export const formatDate = (dateString) => {
  if (!dateString || dateString === "0001-01-01T00:00:00") return 'N/A';
  try {
    return format(parseISO(dateString), 'dd MMM yyyy HH:mm:ss');
  } catch (error) {
    console.error('Error formatting date:', error);
    return dateString;
  }
};


export const handleApiError = (error) => {
  let errorMessage = 'Operation failed';

  if (error.response) {
    if (error.response.data?.errors?.length > 0) {
      errorMessage = error.response.data.errors[0];
    } else if (typeof error.response.data === 'object') {
      errorMessage += `: ${JSON.stringify(error.response.data)}`;
    } else if (typeof error.response.data === 'string') {
      errorMessage += `: ${error.response.data}`;
    } else {
      errorMessage += `: Status ${error.response.status}`;
    }
  } else if (error.request) {
    errorMessage = 'No response received from server';
  } else {
    errorMessage = error.message;
  }

  return errorMessage;
};
