import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { grabToken } from '../../../utils/api';
import { useTheme } from '../../../contexts/ThemeContext';
import { notification } from 'antd';
import ProductDetailsForm from './components/ProductDetailForm';
import ErrorPage from '../../error/ErrorPage';
import LoadingSpinner from '../../../components/misc/LoadingSpinner';
import usePrompt from '../../../hooks/usePrompt';
import {
  FormOutlined,
} from '@ant-design/icons';
import {
  TabNavigation,
  NavigationHeader,
  useFormHandlers,
  handleBackNavigation,
  getCommonStyles,
  confirmNavigation,
  handleApiError
} from '../../../components/helpers/CommonHelper';

const API_CONFIG = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  endpoints: {
    products: '/Products',
  },
};

const ProductDetailPage = () => {
  const [product, setProduct] = useState({
    id: '',
    name: '',
    goal: '',
    labels: '',
    aiEnabled: false,
    createdByUser: { id: '', name: '' },
    updatedByUser: { id: '', name: '' },
    createdDate: '',
    updatedDate: '',
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('details');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const originalValues = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const location = useLocation();

  const { containerClass } = getCommonStyles(isDarkMode);

  const tabs = [
    {
      id: 'details',
      label: 'Details',
      icon: <FormOutlined />
    }
  ];

  const validateField = (name, value) => {
    switch (name) {
      case 'name':
        return value.trim() === '' ? 'Name is required' : '';
      case 'goal':
        return value.trim() === '' ? 'Goal is required' : '';
      default:
        return '';
    }
  };

  const {
    isEditing,
    setErrors,
    setIsEditing,
    handleEditClick,
    handleCancelEdit,
  } = useFormHandlers({
    initialData: product,
    fetchDetails: () => fetchProductDetails(id),
    onSave: (name, value) => {
      setProduct(prev => ({ ...prev, [name]: value }));
      setHasUnsavedChanges(true);
    },
    validateFields: validateField,
    hasUnsavedChanges
  });

  useEffect(() => {
    if (id) {
      fetchProductDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (product && !originalValues.current) {
      originalValues.current = { ...product };
    }
  }, [product]);

  usePrompt('You have unsaved changes. Are you sure you want to leave?', hasUnsavedChanges && isEditing);

  const handleBack = () => {
    confirmNavigation(hasUnsavedChanges, (shouldNavigate) => {
      if (shouldNavigate) {
        handleBackNavigation(navigate, location, '/internal-admin/products');
      }
    });
  };

  const fetchProductDetails = async (productId) => {
    setLoading(true);
    setError(null);
    try {
      const token = await grabToken();
      const response = await axios.get(`${API_CONFIG.baseURL}${API_CONFIG.endpoints.products}/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
      setProduct(response.data.result);
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (formValues) => {
    setLoading(true);

    const updatedProduct = {
      ...product,
      ...formValues
    };

    const formErrors = Object.keys(updatedProduct).reduce((acc, key) => {
      const fieldError = validateField(key, updatedProduct[key]);
      if (fieldError) {
        acc[key] = fieldError;
      }
      return acc;
    }, {});

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setLoading(false);
      return;
    }

    try {
      const token = await grabToken();
      const productData = {
        id: updatedProduct.id,
        name: updatedProduct.name,
        goal: updatedProduct.goal,
        labels: updatedProduct.labels,
        aiEnabled: updatedProduct.aiEnabled
      };

      await axios.put(
        `${API_CONFIG.baseURL}${API_CONFIG.endpoints.products}/${updatedProduct.id}`,
        productData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setProduct(updatedProduct);
      setLoading(false);
      setHasUnsavedChanges(false);
      setIsEditing(false);
      notification.success({
        message: 'Product was updated',
      });

      await fetchProductDetails(updatedProduct.id);
    } catch (error) {
      setLoading(false);
      const errorMessage = handleApiError(error);
      setError(errorMessage);
      notification.error({
        message: 'Product update failed',
        description: errorMessage,
      });
    }
  };

  const renderDetailsTab = () => (
    <ProductDetailsForm
      product={product}
      isEditing={isEditing}
      onSubmit={handleSubmit}
      onCancel={handleCancelEdit}
      onEdit={handleEditClick}
      isDarkMode={isDarkMode}
    />
  );

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorPage isDarkMode={isDarkMode} errorMessage={error} />;

  return (
    <div className={containerClass}>
      <div className="container mx-auto p-6">
        <NavigationHeader
          title={`Product Details | ${product.name}`}
          onBackClick={handleBack}
          isDarkMode={isDarkMode}
        />

        <TabNavigation
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={setActiveTab}
          isDarkMode={isDarkMode}
        />

        {activeTab === 'details' ? renderDetailsTab() : <div>No content available</div>}
      </div>
    </div>
  );
};

export default ProductDetailPage;
